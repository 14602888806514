import { action, makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import backendApis from '../utils/backendApis'
import AuthStore from './AuthStore'

const currentAbTestNames = [
  'purechunk0618',
  'insert0622',
  'related0629',
  'gamedeal0706',
  'brandhide0706',
  'timelimit0709',
]

class UserStore {
  userInfo = {
    _id: '',
    codepushVersion: '',
    appVersion: '',
    userName: '',
    needsLogin: '',
    userImageUri: '',
  }

  abInfo = {}
  accumulatedPoints = 0
  dailyCheckInQuickLinkNotificationCount = 0
  userDealStatus = {}
  targetEventDeal = 'none'
  targetEventGame = 'none'
  sessionId = nanoid()
  enteringComponent = 'webscreen'
  cherryPicker = true
  isRewardAdReady = false
  isWatchingAd = false
  navigation = '' // 현재 currentScreen이 정확하게 관리되지 않고 있음.
  isHiddenPointNudgeAvailable = false
  offerwallInfo = {
    totalReward: 0,
    appId: '',
    unitId: '',
    uniqueUserId: '',
    networkName: 'buzzvil',
    placement: 'DAILYCHECKIN_OFFERWALL',
    platform: '',
    ifa: '00000000-0000-0000-0000-000000000000',
    ifv: '',
    todayTotalReward: 0,
  }

  constructor() {
    makeObservable(this, {
      userInfo: observable.ref,
      abInfo: observable.ref,
      accumulatedPoints: observable.ref,
      userDealStatus: observable.ref,
      targetEventDeal: observable.ref,
      targetEventGame: observable.ref,
      sessionId: observable.ref,
      enteringComponent: observable.ref,
      cherryPicker: observable.ref,
      isRewardAdReady: observable.ref,
      isWatchingAd: observable.ref,
      offerwallInfo: observable.ref,
      navigation: observable.ref,
      isHiddenPointNudgeAvailable: observable.ref,
      setAbInfo: action,
      setAccumulatedPoints: action,
      setDailyCheckInQuickLinkNotificationCount: action,
      setTargetEventDeal: action,
      setTargetEventGame: action,
      dailyCheckInQuickLinkNotificationCount: observable,
      setSessionId: action,
      setEnteringComponent: action,
      setUserInfo: action,
      setUserDealStatus: action,
      setCherryPicker: action,
      setIsRewardAdReady: action,
      setIsWatchingAd: action,
      setOfferwallInfo: action,
      setNavigation: action,
      setIsHiddenPointNudgeAvailable: action,
    })
  }

  setUserInfo(userInfo) {
    this.userInfo = userInfo
    AuthStore.setCodePushVersion(userInfo?.codepushVersion)
  }

  setAbInfo(abInfo) {
    this.abInfo = abInfo
  }

  setAccumulatedPoints(accumulatedPoints) {
    this.accumulatedPoints = accumulatedPoints
  }

  setIsHiddenPointNudgeAvailable(isHiddenPointNudgeAvailable) {
    this.isHiddenPointNudgeAvailable = isHiddenPointNudgeAvailable
  }

  setDailyCheckInQuickLinkNotificationCount(
    dailyCheckInQuickLinkNotificationCount,
  ) {
    this.dailyCheckInQuickLinkNotificationCount =
      dailyCheckInQuickLinkNotificationCount
  }

  setUserDealStatus(userDealStatus) {
    this.userDealStatus = userDealStatus
  }

  setTargetEventDeal(targetEventDeal) {
    this.targetEventDeal = targetEventDeal
  }

  setTargetEventGame(targetEventGame) {
    this.targetEventGame = targetEventGame
  }

  setSessionId(sessionId) {
    this.sessionId = sessionId
  }

  setEnteringComponent(enteringComponent) {
    this.enteringComponent = enteringComponent
  }

  setIsRewardAdReady(isRewardAdReady) {
    this.isRewardAdReady = isRewardAdReady
  }

  setIsWatchingAd(isWatchingAd) {
    this.isWatchingAd = isWatchingAd
  }

  setOfferwallInfo(offerwallInfo, update = false) {
    if (update) {
      this.offerwallInfo = { ...this.offerwallInfo, ...offerwallInfo }
    } else {
      this.offerwallInfo = offerwallInfo
    }
  }

  async loadDailyCheckInQuickLinkNotificationCount() {
    const result =
      await backendApis.loadDailyCheckInQuickLinkNotificationCount()
    if (result?.status === 200) {
      this.setDailyCheckInQuickLinkNotificationCount(result.data)
    }
  }

  async loadCheckInNotificationSettings() {
    const result = await backendApis.getUserCheckInPushNotificationSetting()
    if (result?.status === 200) {
      this.setUserInfo({
        ...this.userInfo,
        pushNotificationSetting: {
          ...this.userInfo.pushNotificationSetting,
          receivingCheckInNotification:
            result?.data?.pushNotificationSetting?.receivingCheckInNotification,
          receivingCheckInNotificationTime:
            result?.data?.pushNotificationSetting
              ?.receivingCheckInNotificationTime,
        },
      })
    }
    return result
  }

  async loadUserDealStatus() {
    const result = await backendApis.getUserDealStatus()
    if (result?.status === 2000) {
      this.setUserDealStatus(result.data)
    }
  }

  async loadTargetEventDeal() {
    const result = await backendApis.getUserDealStatus()
    if (result?.status === 2000) {
      this.setUserDealStatus(result?.data)
      const { dealAuth = [] } = result?.data
      const validDealList = ['treasureDeal', 'categoryDeal']
      const exceptionList = []
      if (this.targetEventDeal !== 'done') {
        dealAuth.forEach((item) => {
          const { endedAt } = item
          const currentDate = new Date()
          const oneDayBefore = currentDate.setDate(currentDate.getDate() - 1)
          if (new Date(endedAt) > new Date(oneDayBefore))
            exceptionList.push(...validDealList)
        })
        const parsedDealList = validDealList.filter(
          (item) => !exceptionList.includes(item),
        )
        const gameSelector = (dealType) => {
          if (dealType === 'treasureDeal') {
            return 'rockPaperScissors'
          }
          if (dealType === 'categoryDeal') {
            return 'randomPick'
          }
        }
        const eventDeal =
          parsedDealList[Math.floor(Math.random() * parsedDealList.length)]
        this.setTargetEventGame(gameSelector(eventDeal) || 'none')
        this.setTargetEventDeal(eventDeal)
      }
    }
  }

  async setCheckInNotificationSettings(
    checkInNotificationSetting,
    checkInNotificationTime,
  ) {
    this.setUserInfo({
      ...this.userInfo,
      pushNotificationSetting: {
        ...this.userInfo.pushNotificationSetting,
        receivingCheckInNotification: checkInNotificationSetting,
        receivingCheckInNotificationTime: checkInNotificationTime,
      },
    })
    const result = await backendApis.recordUserCheckInNotificationSetting(
      checkInNotificationSetting,
      checkInNotificationTime,
    )
    return result
  }

  async loadAbInfo() {
    const result = await backendApis.getAbTestGroup(currentAbTestNames)
    if (result?.status === 200 && result?.data?.length > 0) {
      // console.debug(`Loaded abInfo ${JSON.stringify(result.data)}`)

      const abInfo = result.data.reduce((acc, cur) => {
        const { testName, myTestGroup } = cur
        acc[testName] = myTestGroup
        return acc
      }, {})
      console.debug(`Setting abInfo as ${JSON.stringify(abInfo)}`)
      this.setAbInfo(abInfo)
    } else {
      console.debug('error', result)
    }
  }

  getAbInfo(abKey) {
    return this.abInfo[abKey] || null
  }

  setCherryPicker(cherryPicker) {
    this.cherryPicker = cherryPicker
  }

  async distinguishCherrypicker() {
    const purchaseInfo = await backendApis.getUserOrderData({ days: 40 })
    if (purchaseInfo?.status === 200) {
      const validPurchase = purchaseInfo?.data?.filter((item) =>
        ['arrived', 'reviewed'].includes(item.status),
      )
      if (validPurchase && validPurchase?.length > 0) {
        this.setCherryPicker(false)
      } else {
        this.setCherryPicker(true)
      }
      return 'done'
    }
  }

  setNavigation(screenName) {
    this.navigation = screenName
  }
}

export default new UserStore()
