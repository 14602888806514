import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import AuthStore from '../../stores/AuthStore'
import RouteStore from '../../stores/RouteStore'
import ToastStore from '../../stores/ToastStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import ABStore from '../../stores/treasureHunt/ABStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import backendApis from '../../utils/backendApis'
import utility from '../../utils/utility'
import ToonModal from '../toon/modal/ToonModal'
import THImageText from './THImageText'
import TreasureHuntButton from './TreasureHuntButton'
import TreasureHuntTimer from './TreasureHuntTimer'
import ApplePangModal from './modals/ApplePangModal'
import ApplePangRankModal from './modals/ApplePangRankModal'
import EggMoneyBasicModal from './modals/EggMoneyBasicModal'
import EggMoneyBoxDetail from './modals/EggMoneyBoxDetail'
import EggMoneyInviteModal from './modals/EggMoneyInviteModal'
import EggMoneyKakaoShare from './modals/EggMoneyKakaoShare'
import EggMoneyOrderCompleteModal from './modals/EggMoneyOrderCompleteModal'
import EggMoneyRouletteModal from './modals/EggMoneyRouletteModal'
import EggMoneyTutorialModal from './modals/EggMoneyTutorialModal'
import EggTradeCancelOrderConfirmModal from './modals/EggTradeCancelOrderConfirmModal'
import EggTradeOrderConfirmModal from './modals/EggTradeOrderConfirmModal'
import ScratchCard from './molecules/ScratchCard'

const renderDescription = (text) =>
  text.split('\n').map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </span>
  ))

const CloseButton = ({ onClick, closeButtonStyle }) => {
  const [isPressed, setIsPressed] = useState(false)

  const handlePress = () => {
    setIsPressed(true)
    // onClick()
  }

  const handleRelease = () => {
    setIsPressed(false)
  }

  return (
    <div
      className={`default absolute right-0 -top-10 z-20 duration-200 ${
        isPressed ? 'scale-90' : 'scale-100'
      }
      pointer-events-auto
      `}
      aria-hidden='true'
      onPointerDown={handlePress}
      onPointerUp={() => {
        handleRelease()
        onClick()
      }}
      onPointerCancel={handleRelease}
      onPointerLeave={handleRelease}
      style={closeButtonStyle}
    >
      <img
        className='w-8 h-8'
        alt='Close'
        src='/images/treasureHunt/whiteClose.png'
      />
    </div>
  )
}

const ModalFrame = observer(
  ({
    content,
    className,
    style,
    removeCloseButton = false,
    closeButtonStyle,
    isUpperModal = false,
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const modalRef = useRef(null)

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsOpen(true)
      }, 100) // Delay the opening animation for 100 milliseconds

      return () => clearTimeout(timer)
    }, [])

    const close = () => {
      THModalStore?.handleClose()
    }

    useEffect(() => {
      if (THModalStore?.triggerClose) {
        handleClose()
      }
    }, [THModalStore?.triggerClose])

    const handleClose = () => {
      setIsOpen(false)
      setTimeout(close, 300) // Delay the closing animation before closing the modal
    }

    return (
      <>
        <div
          id='ModalBackground'
          className={`fixed top-0 left-0 w-full h-full bg-black opacity-80 z-[30] transition-opacity duration-300 `}
        />
        <div
          ref={modalRef}
          className={`fixed top-1/2 left-1/2 py-4 z-40 bg-white rounded-xl px-4 transform transition-all duration-300 ${
            isOpen
              ? 'scale-100 translate-x-[-50%] translate-y-[-50%]'
              : 'scale-0 translate-x-[-50%] translate-y-[-50%]'
          }
        ${className}
        
        `}
          style={style}
        >
          {content}
          {!removeCloseButton && (
            <CloseButton
              onClick={handleClose}
              closeButtonStyle={closeButtonStyle}
            />
          )}
        </div>
      </>
    )
  },
)

const WatermelonRule = ({ config }) => {
  const content = (
    <div
      className='items-center justify-center default'
      style={{
        fontFamily: 'maplestory',
      }}
    >
      <div className='default font-bold text-center text-[6vw] mb-2'>
        {config?.title ?? '규칙 설명'}
      </div>
      <div className='relative flex items-center justify-center w-full mb-2'>
        <img
          alt='main'
          src={`../watermelonAssets/${config?.src ?? 'rule3'}.png`}
          className={`relative z-[10] w-full ${config?.imgClassName}`}
        />
      </div>

      {/* <div
        className={`default font-bold text-center text-[4vw] mt-6 ${config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={3.8}
          style={{
            color: '#313138',
          }}
          text={`
같은 작물끼리 합치면 다음 작물로 변해요!
양파를 만들면 무료로 양파 1Kg를 드려요!
`}
        />
      </div> */}
      <TreasureHuntButton
        className='w-full py-3 mt-0'
        clickable
        text={config?.text ?? '닫기'}
        onClick={() => {
          THModalStore?.close()
          if (config?.onClick) {
            config?.onClick()
          }
          if (config?.openBottomSheet) {
            THBottomSheetStore.setType({
              type: config?.openBottomSheet?.type,
            })
          }
          if (config?.sendRN) {
            TreasureHuntAction.sendRN(config?.sendRN)
          }
        }}
      />
    </div>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
}

const WatermelonReview = observer(() => {
  const [reviewData, setReviewData] = useState([])

  useEffect(() => {
    const fetchReviewData = async () => {
      const review = await backendApis.getWinningItemReviews()
      setReviewData(review?.data)
    }
    if (THModalStore?.type === 'watermelonReview') {
      fetchReviewData()
    }
  }, [])

  if (reviewData?.length === 0) {
    return <></>
  }
  const content = (
    <div
      className='items-center justify-center default'
      style={{
        fontFamily: 'maplestory',
      }}
    >
      <div className='default font-bold text-center text-[6vw] mb-2'>
        당첨 후기
      </div>
      {reviewData?.length > 0 && (
        <div
          style={{
            position: 'relative',
            overflowY: 'scroll',
            width: '98%',
            marginTop: '3vh',
            marginBottom: '3vh',
            zIndex: 1999,
            background: 'white',
            borderRadius: '5vw',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            height: '95vw',
          }}
        >
          {reviewData?.map((item) => {
            return (
              <div
                key={Math.random()}
                style={{
                  marginRight: '4vw',
                  marginLeft: '4vw',
                  marginTop: '4vw',
                }}
              >
                <div
                  style={{
                    zIndex: 1999,
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      marginTop: 4,
                    }}
                  >
                    <img
                      style={{
                        zIndex: 1999,
                        width: 30,
                        height: 30,
                        borderRadius: 50,
                      }}
                      src={item?.userImageUri}
                      onError={(e) => {
                        e.target.src = WatermelonUserStore?.DEFAULT_IMAGE
                      }}
                      alt='abc'
                    />

                    <div
                      style={{
                        color: '#5F2D0C',
                        zIndex: 1999,
                        fontFamily: 'maplestory',
                        fontSize: 14,
                        marginLeft: 8,
                      }}
                    >
                      {item?.userName}
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignContent: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        color: '#855C36',
                        zIndex: 1999,
                        fontFamily: 'maplestory',
                        fontSize: 12,
                      }}
                    >
                      {moment(item?.createdAt).format('YYYY-MM-DD')}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: '#525964',
                      zIndex: 1999,
                      fontFamily: 'maplestory',
                      marginBottom: 12,
                      marginTop: 12,
                      fontSize: 14,
                      flexDirection: 'column',
                      display: 'flex',
                    }}
                  >
                    {item?.text?.length > 150 ? (
                      <>{item?.text.slice(0, 150)}...</>
                    ) : (
                      <>{item?.text.slice(0, 150)}</>
                    )}
                  </div>
                </div>
                {item?.reviewImageUris?.map((image) => (
                  <img
                    key={Math.random()}
                    style={{
                      zIndex: 1999,
                      width: '30%',
                      height: 80,
                      borderRadius: 5,
                      marginBottom: 16,
                      marginRight: 8,
                    }}
                    src={image}
                    alt='abc'
                  />
                ))}

                <div>
                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#D3D7DD',
                      flex: 1,
                      flexDirection: 'column',
                      width: '100%',
                      alignContent: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
      {!reviewData && <div>아직 후기가 없어요</div>}
      <TreasureHuntButton
        className='w-full py-3 mt-0'
        clickable
        text='닫기'
        onClick={() => {
          THModalStore?.close()
        }}
      />
    </div>
  )

  return <ModalFrame content={content} className='w-[80%]' />
})

const WatermelonCurrentRank = observer(() => {
  const [disabledRows, setDisabledRows] = useState([])

  const content = (
    <div
      className='items-center justify-center default'
      style={{
        fontFamily: 'maplestory',
      }}
    >
      <div className='default font-bold text-center text-[6vw] mb-2'>
        이번 판 점수
      </div>
      <div className='relative mb-2'>
        <img
          alt='main'
          src='/watermelonAssets/boastCurrentScore.png'
          className={`w-[46vw] relative z-[10] `}
        />
        <div className='bottom-[1vh] left-[18vw] absolute z-[10] font-semibold'>
          {WatermelonUserStore?.currentScore}점
        </div>
      </div>

      <div className='w-full py-4'>
        <div className='font-bold text-center text-[4vw] py-2'>
          친구들보다 점수를 높게 받았어요!
        </div>
        {WatermelonUserStore?.usersBelowCurrentRank?.map((item, index) => {
          const isRowDisabled = disabledRows.includes(index)

          return (
            <div
              key={Math.random()}
              className='flex flex-row items-center mb-2 '
            >
              <div className='flex flex-row justify-between items-center p-2 w-full h-[6vh] bg-[#FFF0F0] rounded'>
                <div className='z-[99] flex flex-row items-center'>
                  <img
                    className='rounded-full w-[7vw] h-[7vw] z-[99]'
                    src={item?.userImageUri}
                    onError={(e) => {
                      e.target.src = WatermelonUserStore?.DEFAULT_IMAGE
                    }}
                    alt='abc'
                  />

                  <div
                    style={{
                      color: '#5F2D0C',
                      zIndex: 99,
                      fontFamily: 'maplestory',
                      fontSize: 14,
                      fontWeight: 'bold',
                      marginLeft: 8,
                    }}
                  >
                    {item?.userName?.length > 5
                      ? `${item?.userName.slice(0, 5)}..`
                      : item?.userName}
                  </div>
                </div>

                <div className='z-[99] text-[3.6vw] font-semibold'>
                  {item?.highscore}점
                </div>
              </div>
              <button
                type='button'
                className={`rounded text-white text-[3.2vw] items-center flex flex-row justify-center font-semibold p-2 ml-2 w-[24vw] h-[6vh] ${
                  isRowDisabled
                    ? 'bg-gray-500 cursor-not-allowed'
                    : 'bg-red-500'
                }`}
                onClick={async () => {
                  if (!isRowDisabled) {
                    backendApis.commonInsert({
                      data: {
                        type: 'clickBoastButton',
                        gameType: WatermelonUserStore?.gameType,
                      },
                      collection: 'watermelon_clickLog',
                    })

                    const pushResult = await backendApis.sendBoastAppPush({
                      friendId: item?.userId,
                      score: Number(
                        WatermelonUserStore?.currentScore - item?.highscore,
                      ),
                    })
                    if (pushResult?.status === 200) {
                      ToastStore.toastOn({
                        type: 'emoji',
                        message: `친구한테 자랑했어요!`,
                        duration: 2000,
                        emoji: '😎',
                      })

                      setDisabledRows((prevRows) => [...prevRows, index])
                    }
                  }
                }}
              >
                {isRowDisabled ? '완료!' : '자랑하기'}
              </button>
            </div>
          )
        })}
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-0'
        clickable
        text='닫기'
        onClick={() => {
          THModalStore?.close()
        }}
      />
    </div>
  )

  return <ModalFrame content={content} className='w-[80%]' />
})

///
/// 코인 사용 모달
///
const WatermelonEnterCoinShop = observer(({ config }) => {
  const [totalPrice, setTotalPrice] = useState(0)
  const [productQuantityList, setProductAmountList] = useState([])

  useEffect(() => {
    if (config) {
      // Set states or perform actions based on config
      setProductAmountList(new Array(config.productList?.length ?? 0).fill(0))
    }
  }, [config])

  // update total price with quantities when productQuantityList changes
  useEffect(() => {
    const subTotal = productQuantityList.reduce(
      (total, productQuantity, index) => {
        return (
          total +
          Number(config?.productList?.[index]?.price) * Number(productQuantity)
        )
      },
      0,
    ) // Initialize total as 0
    setTotalPrice(subTotal)
  }, [productQuantityList])

  const content = (
    <div
      className='items-center justify-center default'
      style={{ fontFamily: 'maplestory' }}
    >
      <div className='default font-bold text-center text-[6vw] mb-0'>
        도전권 구매
      </div>
      <div className='border-1 rounded-lg px-[14vw] py-[2vw] text-center my-[2vw] bg-red-100 bg-opacity-40 flex items-center justify-center font-bold whitespace-nowrap'>
        내 코인:&nbsp;
        <img
          className='w-[3vw] h-[3vw] mx-1'
          alt='coin'
          src='/watermelonAssets/shop/coin.png'
        />
        <div className='text-red-400'>
          {`${commaNumber(WatermelonUserStore?.userData?.coin ?? 0)}`}
        </div>
      </div>
      <div className='grid grid-cols-1 '>
        {config?.productList?.map((product, index) => (
          <div key={product.name} className='flex items-center justify-center'>
            <button
              className='p-[4vw] w-[14vw] h-auto justify-center items-center'
              onClick={() => {
                // 수량 감소 기능
                setProductAmountList(
                  productQuantityList.map((item, i) =>
                    i === index && item > 0 ? item - 1 : item,
                  ),
                )
              }}
              type='button'
            >
              <img
                className='flex'
                alt={product}
                src='/watermelonAssets/shop/minus.png'
              />
            </button>
            <div className='px-[3vw] py-[3vw] w-[45vw] h-auto border-1 rounded-lg text-center bg-gray-300 bg-opacity-40 flex items-center justify-start font-bold'>
              <img
                className='flex w-[8vw] h-auto'
                alt={product}
                src={`../watermelonAssets/shop/${product.name}.png`}
              />
              <div className='text-xs font-bold mx-[2vw]'>
                {product.nameKOR} {productQuantityList[index]}개
              </div>
            </div>
            <button
              className='p-4 w-[14vw] h-auto'
              onClick={() => {
                // 수량 증가 기능
                setProductAmountList(
                  productQuantityList.map((item, i) =>
                    i === index ? item + 1 : item,
                  ),
                )
              }}
              type='button'
            >
              <img
                className='flex'
                alt={product}
                src='/watermelonAssets/shop/plus.png'
              />
            </button>
          </div>
        ))}
      </div>
      <div className='border-1 rounded-lg px-20 py-2 text-center my-[2vw] bg-red-100 bg-opacity-40 flex items-center justify-center font-bold whitespace-nowrap'>
        가격:&nbsp;
        <img
          className='w-[3vw] h-[3vw] mx-1'
          alt='cash'
          src='/watermelonAssets/shop/coin.png'
        />
        <div className='text-red-400'>{`${commaNumber(totalPrice)}`}</div>
      </div>

      <div className='flex flex-row w-full space-x-4'>
        <TreasureHuntButton
          className='flex-1 py-3 mt-2'
          clickable={
            totalPrice !== 0 &&
            WatermelonUserStore?.userData?.coin >= totalPrice
          }
          text={config?.text ?? '구매하기'}
          onClick={() => {
            config?.onClickBuy(productQuantityList, totalPrice)
            THModalStore?.close()
          }}
        />
      </div>
    </div>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
})

const WatermelonProductDrawNotice = observer(({ config }) => {
  const [index, setIndex] = useState(0)
  const descList = ['productDrawRule_0', 'productDrawRule_1']
  const buttonDescList = ['다음으로', '확인했어요']

  const onPressNext = ({ dir }) => {
    const i = (index + dir + descList.length) % descList.length
    setIndex(i)
  }

  const content = (
    <div
      className='items-center justify-center default'
      style={{ fontFamily: 'maplestory' }}
    >
      <div className='default font-bold text-center text-[6vw] mb-0'>
        응모 사용법 변경 안내
      </div>
      <img
        className='w-[80vw] h-auto mt-8 mb-8'
        alt='Product Draw Rule-0'
        src={`../watermelonAssets/productDraw/${
          descList[(index + descList.length) % descList.length]
        }.png`}
      />

      <div className='flex flex-row w-full space-x-4'>
        <TreasureHuntButton
          className='flex-1 py-3 mt-2'
          clickable
          text={
            buttonDescList[
              (index + buttonDescList.length) % buttonDescList.length
            ]
          }
          onClick={() => {
            if (index === descList.length - 1) {
              THModalStore?.close()
            }
            onPressNext({ dir: -1 })
          }}
        />
      </div>
    </div>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
})

const WatermelonEnterDrawFinish = observer(({ config }) => {
  const content = (
    <div
      className='items-center justify-center default'
      style={{ fontFamily: 'maplestory' }}
    >
      <div className='default font-bold text-center text-[6vw] mb-0'>
        응모 완료!
      </div>
      <img
        className='w-[30vw] h-auto mt-8 mb-3'
        src='/watermelonAssets/productDraw/goodLuck.png'
        alt='good luck'
      />

      <div className='w-[30vw] flex justify-center items-center mb-5'>
        <img alt='main' src={config?.src} />
      </div>
      <div className='w-[80vw] flex justify-center items-center whitespace-pre-line mb-5 text-center'>
        {`축하해요! ${config?.type}에 응모됐어요!\n 내일 결과를 확인해 보세요.`}
      </div>

      <div className='flex flex-row w-full space-x-4'>
        <TreasureHuntButton
          className='flex-1 py-3 mt-2'
          clickable={config?.clickable}
          text={config?.text ?? '확인'}
          onClick={() => {
            THModalStore?.close()
          }}
        />
      </div>
    </div>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
})

const SinglePageNotice = observer(({ config }) => {
  const content = (
    <div
      className='items-center justify-center default'
      style={{ fontFamily: 'maplestory' }}
    >
      <div className='default font-bold text-center text-[6vw] mb-0'>
        {config?.title}
      </div>
      <div className='w-[60vw] flex justify-center items-center my-5'>
        <img alt='main' src={config?.src} />
      </div>

      <div className='flex flex-row w-full space-x-4'>
        <TreasureHuntButton
          className='flex-1 py-3 mt-2'
          clickable
          text={config?.text ?? '확인'}
          onClick={() => {
            THModalStore?.close()
          }}
        />
      </div>
    </div>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
})

///
/// 더 이상 사용하지 않음
///
const WatermelonChoseTicket = observer(({ config }) => {
  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-0'>
        도전권 선택
      </div>
      <div className='relative flex items-center justify-center w-full'>
        <img
          alt='main'
          src='/watermelonAssets/choseTicket.png'
          className={`relative z-[10] `}
        />
      </div>

      <div className='flex flex-row w-full space-x-4'>
        <TreasureHuntButton
          className='flex-1 py-3 mt-2'
          clickable={config.fixedAvailble}
          text={config?.text ?? '100% 도전권'}
          onClick={() => {
            THModalStore?.close()
            config.onFixedClick()
          }}
        />
        <TreasureHuntButton
          className='flex-1 py-3 mt-2'
          clickable={config.normalAvailable}
          text={config?.text ?? '일반 도전권'}
          onClick={() => {
            THModalStore?.close()
            config.onNormalClick()
          }}
        />
      </div>
    </div>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
})

const Reward = ({ config }) => {
  const content = (
    <div
      className='items-center justify-center default'
      style={!config?.removeFont ? { fontFamily: 'maplestory' } : {}}
    >
      <div className='default font-bold text-center text-[6vw] mb-4'>{`${
        config?.amount ? commaNumber(config?.amount) : ''
      } ${config?.rewardName}`}</div>
      <div className='relative flex items-center justify-center w-full'>
        {config?.earnEffect && (
          <img
            className='absolute w-[40vw] h-[40vw] z-[0]  rotate-infinite'
            alt='earnEffect'
            src='/images/treasureHunt/earnEffect.png'
          />
        )}
        <img
          alt='main'
          src={
            config.src === '../images/toon/toonCoin.png'
              ? '/images/toon/toonCoin.png'
              : config.src
          }
          className={`relative z-[10] h-[25vw] ${config?.imgClassName}`}
        />
      </div>

      <div
        className={`default font-bold text-center text-[4vw] mt-6 ${config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={4}
          style={{
            color: '#313138',
          }}
          text={config?.description}
        />
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable
        text={config?.text ?? '닫기'}
        onClick={() => {
          THModalStore?.close()
          if (config?.onClick) {
            config?.onClick()
          }
          if (config?.openBottomSheet) {
            THBottomSheetStore.setType({
              type: config?.openBottomSheet?.type,
            })
          }
          if (config?.sendRN) {
            TreasureHuntAction.sendRN(config?.sendRN)
          }
          if (config?.onClickTypeFromBack === 'goldenEggHatcher') {
            TreasureHuntAction.giveGoldenEggReward()
          }
          if (config?.onClickTypeFromBack === 'watermelonTeamgame') {
            RouteStore?.navigate(
              `/watermelon/teamgame?token=${AuthStore?.token}`,
            )
          }
        }}
      />
    </div>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
}
const LinkShare = ({ config }) => {
  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        {`${config?.title}`}
      </div>

      <img
        alt='main'
        src={config.src}
        className={`w-[100%] rounded-lg ${config?.imgClassName}`}
      />
      <div className='default font-bold text-center text-[4vw] mt-4 '>
        {renderDescription(`${config?.description}`)}
      </div>
      {config?.description2 && (
        <div className='default font-bold text-center text-[3.5vw] text-[#6f6f6f]'>
          {renderDescription(`${config?.description2}`)}
        </div>
      )}
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable
        text={config?.buttonText ?? '초대하기'}
        onClick={() => {
          if (config?.onClick) {
            config?.onClick()
          }
        }}
      />
    </div>
  )

  return <ModalFrame content={content} className='w-[80%]' />
}
const GoldenEgg = ({ config }) => {
  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>{`${
        config?.amount ? commaNumber(config?.amount) : ''
      } ${config?.rewardName}`}</div>
      <div className='relative flex items-center justify-center w-full'>
        <img
          className='absolute w-[40vw] h-[40vw] z-[0]  rotate-infinite'
          alt='earnEffect'
          src='/images/treasureHunt/earnEffect.png'
        />
        <img
          alt='main'
          src='/images/treasureHunt/modal/goldenEgg.png'
          className='relative z-[10] h-[25vw]'
        />
      </div>

      <div
        className={`default font-bold text-center text-[4vw] mt-6 ${config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={4}
          style={{
            color: '#313138',
          }}
          text={config?.description}
        />
      </div>

      <div className='flex flex-row justify-center items-center font-bold text-[#ff5951] mt-4'>
        <TreasureHuntTimer
          style={{
            color: '#ff5951',
            marginRight: 4,
          }}
          format='number'
          expiryTimestamp={moment(THUserStore?.goldenEggEndAt).toDate()}
        />
        후에 사라져요!
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable
        text={config?.text ?? '닫기'}
        onClick={() => {
          THModalStore?.close()
          // if (THUserStore?.energy > 0) {
          //   TreasureHuntAction.triggerFindTreasure()
          // }
        }}
      />
    </div>
  )

  return <ModalFrame content={content} className='w-[80%]' />
}
const SearchNotice = ({ config }) => {
  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>{`${
        config?.amount ? commaNumber(config?.amount) : ''
      } ${config?.rewardName}`}</div>
      <img
        alt='main'
        src={config.src}
        className={`h-[25vw] ${config?.imgClassName}`}
      />

      <div
        className={`default font-bold text-center text-[4vw] mt-6 ${config?.descriptionClassName}`}
      >
        {renderDescription(`${config?.description}`)}
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable
        text={config?.text ?? '닫기'}
        onClick={() => {
          THModalStore?.close()
          // trigger find
          TreasureHuntAction.triggerFindTreasure()
        }}
      />
    </div>
  )

  return <ModalFrame content={content} className='w-[80%]' />
}

const DailyPointBonus = ({ config }) => {
  const [selected, setSelected] = useState('tomorrow')

  const content = (
    <div className='default justify-center items-center text-[#313138]'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        보너스 보상 선택
      </div>
      <div className='default font-bold text-center text-[4vw] mb-4'>
        {renderDescription(`두개의 보상 중에 하나를 선택해주세요!`)}
      </div>
      <div className='flex flex-row items-center justify-around flex-1 w-full font-bold'>
        <div
          className={`default justify-center items-center border-[2px] px-6 py-2 rounded-xl
          ${selected === 'now' && 'border-[#FF5951] bg-[#FFF6F6]'}
          `}
          onClick={() => setSelected('now')}
        >
          <div className='text-[3.8vw]'>지금</div>
          <img
            alt='main'
            src='/images/treasureHunt/point.png'
            className={`h-[20vw] my-2 `}
          />
          <div>{`${config?.rewardAmount ?? 8}원 받기`}</div>
        </div>
        <div
          className={`default justify-center items-center border-[2px] px-6 py-2 rounded-xl
          ${selected === 'tomorrow' && 'border-[#FF5951] bg-[#FFF6F6]'}
          `}
          onClick={() => setSelected('tomorrow')}
        >
          <div className='text-[3.8vw]'>내일</div>
          <img
            alt='main'
            src='/images/treasureHunt/point.png'
            className={`h-[20vw] my-2 `}
          />
          <div>{`${config?.tomorowRewardAmount ?? 60}원 받기`}</div>
        </div>
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable
        text='선택 완료'
        onClick={() => {
          TreasureHuntAction.getDailyPointBonus({
            selected,
            tomorowRewardAmount: config?.tomorowRewardAmount,
          })
        }}
      />
    </div>
  )

  return <ModalFrame content={content} className='w-[80%]' />
}
const InfoList = ({ config }) => {
  const content = (
    <>
      <div className='flex font-bold text-center text-[6vw] mb-2 p-2'>{`${config?.title}`}</div>

      <div className='flex flex-col overflow-y-scroll max-h-[30vh]'>
        {config?.textArray?.map((text, index) => (
          <div
            key={Math.random()}
            className='flex text-left p-2 py-1 font-bold text-[#595864] flex-row w-full text-[3.5vw]'
          >
            <div>{index + 1}.&nbsp;</div>
            <div
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'keep-all',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {text}
            </div>
          </div>
        ))}
      </div>

      <TreasureHuntButton
        text='닫기'
        clickable
        className='w-full py-3 mt-6'
        onClick={() => {
          if (config?.onClick) {
            config.onClick()
          } else {
            THModalStore?.close()
          }
        }}
      />
    </>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
}
const ServiceEndNotice = ({ config }) => {
  const content = (
    <>
      <div className='flex font-bold text-center text-[6vw] mb-2 p-2'>{`${config?.title}`}</div>

      <div className='flex flex-col overflow-y-scroll max-h-[60vh]'>
        {config?.textArray?.map((text, index) => (
          <div
            key={Math.random()}
            className='flex text-left p-2 py-1 font-bold text-[#595864] flex-row w-full text-[3.5vw]'
          >
            <div
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'keep-all',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {text}
            </div>
          </div>
        ))}
      </div>

      <TreasureHuntButton
        text='닫기'
        clickable
        className='w-full py-3 mt-6'
        onClick={() => {
          if (config?.onClick) {
            config.onClick()
          } else {
            THModalStore?.close()
          }
        }}
      />
    </>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={config?.removeCloseButton}
    />
  )
}

const FriendDelete = observer(({ config }) => {
  const FriendDeleteItem = ({ friend }) => (
    <div className='flex flex-row items-center justify-between w-full my-2'>
      <div className='flex flex-row items-center'>
        <img
          className='w-[12vw] h-[12vw] rounded-full mr-2'
          alt='profile'
          src={friend.userImageUri ?? THUserStore?.DEFAULT_IMAGE}
        />
        <span>{utility.shortenText(friend?.userName ?? '보물친구', 8)}</span>
      </div>
      <TreasureHuntButton
        className='bg-red-500'
        clickable
        text='삭제'
        style={{
          paddingTop: 6,
          paddingBottom: 6,
        }}
        onClick={() => {
          THModalStore?.close()
          THModalStore?.setType({
            type: 'confirm',
            config: {
              title: '보물친구 삭제',
              src: '../images/treasureHunt/emoji/face/73.png',
              description: `${friend?.userName}님을 정말 삭제하시겠어요?`,
              onConfirm: () => {
                TreasureHuntAction.deleteFriend({ friendId: friend?.friendId })
                THModalStore.close()
              },
              clickable: true,
            },
          })
        }}
      />
    </div>
  )

  const content = (
    <div className='flex flex-col items-center justify-center '>
      <div className='flex flex-col font-bold text-center text-[6vw] mb-4'>
        보물친구 삭제
      </div>
      <div className='flex flex-col justify-start items-center w-full overflow-y-scroll max-h-[80vw]'>
        {THUserStore?.friends?.map((friend) => (
          <FriendDeleteItem key={friend?.friendId} friend={friend} />
        ))}
      </div>

      <TreasureHuntButton
        className='w-full py-3 mt-8'
        clickable
        text='닫기'
        onClick={() => {
          THModalStore?.close()
        }}
      />
    </div>
  )

  return <ModalFrame content={content} className='w-[90%]' />
})

const Confirm = ({ config }) => {
  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        {config?.title}
      </div>
      {config?.src ? (
        <img
          alt='main'
          src={config.src}
          className={config?.buttonText === '광고 보기' ? 'w-full' : 'h-[25vw]'}
        />
      ) : null}
      {config?.description && (
        <div className='default font-bold text-center text-[4vw] mt-6 overflow-y-scroll max-h-[320px]'>
          {renderDescription(`${config?.description}`)}
        </div>
      )}
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable={config.clickable}
        isLoading={config.isLoading}
        text={config?.buttonText ?? '확인'}
        onClick={config.onConfirm}
      />
    </div>
  )
  return <ModalFrame content={content} className='w-[80%]' />
}

const UploadChallenge = ({ config }) => {
  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        {ABStore?.uploadChallenge === 'b'
          ? '삼행시 챌린지 발견'
          : '게시글 챌린지 발견'}
      </div>
      <img
        alt='main'
        src={config.src ?? '../images/treasureHunt/uploadChallenge/modal.png'}
        className={`h-[25vw] ${config?.imgClassName}`}
      />

      <div
        className={`default font-bold text-center text-[4vw] mt-6 ${config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={4}
          style={{
            color: '#313138',
          }}
          text={
            '커뮤니티에 삼행시를 올리기만하면\n최대 <point> 600원을 받아요!'
          }
        />
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable
        text='챌린지 시작하기'
        onClick={() => {
          THModalStore?.close()
          TreasureHuntAction.startUploadChallenge()
        }}
      />
    </div>
  )
  return <ModalFrame content={content} className='w-[80%]' />
}
const SearchButtonEnforce = observer(() => {
  useEffect(() => {
    if (!THModalStore?.config?.isFromEventButton) {
      if (THModalStore?.config?.isPurchase) {
        window?.localStorage?.setItem(
          'purchaseSearchButtonEnforceShowEndAt',
          moment().add(3, 'h').toDate(),
        )
        setTimeout(() => {
          THUserStore?.set('purchaseSearchButtonEnforceOn', true)
        }, 500)
      } else if (THModalStore?.config?.isFree) {
        // console.log('none');
      } else {
        window?.localStorage?.setItem(
          'searchButtonEnforceShowEndAt',
          moment().add(3, 'h').toDate(),
        )
        setTimeout(() => {
          THUserStore?.set('searchButtonEnforceOn', true)
        }, 500)
      }
    }
  }, [])

  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        {`${THModalStore?.config?.title} ${
          THModalStore?.config?.isPurchase ? '[2일권]' : ''
        }${
          !THModalStore?.config?.isPurchase && !THModalStore?.config?.isFree
            ? '[5일권]'
            : ''
        }`}
      </div>
      <img
        alt='main'
        src='/images/treasureHunt/modal/searchButtonEnhance.png'
        className='w-full'
      />

      <div className='w-full flex flex-col bg-[#ffecec] border-[#ff5951] justify-start items-center p-4 py-2 rounded-xl '>
        <div className='text-[4vw] font-bold mb-2'>강화 혜택</div>
        <div className='flex flex-row justify-start items-center font-semibold w-full my-1 text-[3.5vw]'>
          <img
            className='w-[5vw] h-[5vw] mr-2 '
            src='/images/treasureHunt/uploadChallenge/1.png'
            alt='letter'
          />{' '}
          캐릭터 발견 수&nbsp;<span className='text-[#FF5951]'>2배</span>
        </div>
        <div className='flex flex-row justify-start items-center font-semibold w-full my-1 text-[3.5vw]'>
          <img
            className='w-[5vw] h-[5vw] mr-2'
            src='/images/treasureHunt/uploadChallenge/2.png'
            alt='hand'
          />{' '}
          탐색시 행운 소모&nbsp;<span className='text-[#FF5951]'>50%</span>
        </div>
        <div className='flex flex-row justify-start items-center font-semibold w-full my-1 text-[3.5vw]'>
          <img
            className='w-[5vw] h-[5vw] mr-2'
            src='/images/treasureHunt/uploadChallenge/3.png'
            alt='hand'
          />{' '}
          탐색 성공 확률&nbsp;<span className='text-[#FF5951]'>100%</span>
        </div>
      </div>
      <div
        className={`default font-bold text-center text-[4vw] mt-4 ${THModalStore?.config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={4}
          style={{
            color: '#313138',
          }}
          text={THModalStore?.config?.description}
        />
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable
        text={THModalStore?.config?.text}
        onClick={() => {
          if (THModalStore?.config?.isPurchase) {
            THModalStore.close()
            const payload = {
              cloverCount: 6,
              enteringComponent: 'TreasureHunt_PurchaseSearchButtonEnforce',
              text: '보물찾기',
              source:
                'https://assets.ilevit.com/922e49f9-905e-43c7-a5f2-81183a316c03.png',
              rewardText: `상품을 구매하고 탐색버튼 [2일] 강화권을 받아보세요`,
            }

            TreasureHuntAction.navigateRN({
              screen: 'TreasureHuntMissionPurchaseScreen',
              payload,
            })
          } else if (THModalStore?.config?.isFree) {
            // logkjsahdf
            THModalStore?.close()
          } else {
            TreasureHuntAction.linkShare({
              shareType: 'TreasureHunt_SearchButtonEnforce',
              title: `[보물찾기] ${
                THUserStore?.userName ? `${THUserStore?.userName}님이` : ''
              } 1,000원을 선물했어요!`,
              description: '보물찾기로 매달 신상 귀걸이 공짜로 받기',
              imageUrl:
                'https://assets.ilevit.com/27629713-faf7-48da-bf4a-586cd6672080.png',
              payload: {},
            })
          }
        }}
      />
    </div>
  )
  return <ModalFrame content={content} className='w-[80%]' />
})
const EnergyMachine = observer(() => {
  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        번개생산기
      </div>
      <THImageText
        fontSize={4}
        style={{
          color: '#313138',
          marginTop: '4vw',
          marginBottom: '4vw',
        }}
        text='매일 최대<energy>10개를 추가로 받을 수 있어요!'
      />
      <img
        className='absolute w-[40vw] h-[40vw] z-[0] -mt-4 rotate-infinite'
        alt='earnEffect'
        src='/images/treasureHunt/earnEffect.png'
      />
      <img
        alt='main'
        src='/images/treasureHunt/energyMachine/machine.png'
        className='w-[20vw] z-[10]'
      />

      <div
        className={`default font-bold text-center text-[4vw] mt-[10vw] ${THModalStore?.config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={4}
          style={{
            color: '#313138',
          }}
          text={`친구를 초대하고 영구적으로\n번개생산기를 받아보세요!`}
        />
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable
        text='새친구 초대하고 생산기 받기'
        onClick={() => {
          TreasureHuntAction.linkShare({
            shareType: 'TreasureHunt_EnergyMachine',
            title: `[보물찾기] ${
              THUserStore?.userName ? `${THUserStore?.userName}님이` : ''
            } 1,000원을 선물했어요!`,
            description: '보물찾기로 하루에 500원씩 캐시 받기',
            imageUrl:
              'https://assets.ilevit.com/27629713-faf7-48da-bf4a-586cd6672080.png',
            payload: {},
          })
        }}
      />
    </div>
  )
  return <ModalFrame content={content} className='w-[80%]' />
})

const DiceEvent = ({ config }) => {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    const lastDiceEvent = window?.localStorage?.getItem('lastDiceEventAt')
    if (lastDiceEvent === moment().format('YYYY-MM-DD')) {
      THModalStore?.close()
      return
    }
    window?.localStorage?.setItem(
      'lastDiceEventAt',
      moment().format('YYYY-MM-DD'),
    )
  }, [])

  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>{`${
        config?.amount ? commaNumber(config?.amount) : ''
      } ${config?.rewardName}`}</div>
      <img
        alt='main'
        src='/images/treasureHunt/diceEvent/normalDice.png'
        className={`h-[25vw] ${config?.imgClassName} ${
          clicked && 'THDiceRollEffect'
        }`}
      />

      <div
        className={`default font-bold text-center text-[4vw] mt-6 ${config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={4}
          style={{
            color: '#313138',
          }}
          text={config?.description}
        />
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable={!clicked}
        text={config?.text ?? '닫기'}
        onClick={() => {
          if (!clicked) {
            setClicked(true)
            setTimeout(() => {
              THModalStore?.close()
              TreasureHuntAction.giveReward({
                rewardAmount: config?.cloverCount,
                rewardType: 'starboxTierOne',
                tag: 'diceEvent',
              })
              THModalStore?.setType({
                type: 'reward',
                config: {
                  rewardName: `주사위 ${config?.cloverCount} 당첨!`,
                  description: `보상으로 <starboxTierOne> ${config?.cloverCount}개 획득!`,
                  src: '../images/treasureHunt/starboxTierOne.png',
                  text: `보상 받기`,
                  onClick: () => {
                    window?.localStorage?.setItem(
                      'diceEventPurchaseEndAt',
                      moment().add(3, 'h').toDate(),
                    )
                    setTimeout(() => {
                      THUserStore?.set('diceEventPurchaseOn', true)
                    }, 500)
                    THModalStore?.setType({
                      type: 'reward',
                      config: {
                        rewardName: '황금 주사위 찬스',
                        description: `지금 상품을 구매하면 <starboxTierTwo> 6개 획득!`,
                        src: '../images/treasureHunt/diceEvent/goldenDice.png',
                        text: `지금 구매하기`,
                        onClick: () => {
                          const payload = {
                            cloverCount: 6,
                            enteringComponent: 'TreasureHunt_DiceEventPurchase',
                            text: '보물찾기',
                            source:
                              'https://assets.ilevit.com/a24c6aab-fb8f-414d-93c6-b842a7741937.png',
                            rewardText: `상품을 구매하고 황금 클로버 6개를 받아보세요`,
                          }

                          TreasureHuntAction.navigateRN({
                            screen: 'TreasureHuntMissionPurchaseScreen',
                            payload,
                          })
                        },
                      },
                    })
                  },
                },
              })
            }, 1500)
          }
        }}
      />
    </div>
  )

  return <ModalFrame content={content} className='w-[80%]' />
}

const ClosetDraw = ({ config }) => {
  const [clicked, setClicked] = useState(false)

  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-2'>
        장식 뽑기
      </div>
      <img
        alt='main'
        src='/images/treasureHunt/closet/box.png'
        className={`h-[50vw] ${config?.imgClassName} ${
          clicked && 'THClosetDraw'
        }`}
      />

      <div
        className={`default font-bold text-center text-[4vw] mt-0 ${config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={4}
          style={{
            color: '#313138',
          }}
          text={config?.description}
        />
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable={!clicked && THUserStore?.point > 300}
        text='뽑기 ('
        secondTextImg='/images/treasureHunt/pointOutline.png'
        secondText='-300원)'
        secondTextImgStyle={{ marginLeft: 0, marginRight: '2px' }}
        onClick={() => {
          if (!clicked) {
            setClicked(true)
            setTimeout(() => {
              THModalStore?.close()
              TreasureHuntAction.closetDraw()
            }, 3500)
          }
        }}
      />
    </div>
  )

  return (
    <ModalFrame
      content={content}
      className='w-[80%]'
      removeCloseButton={clicked}
    />
  )
}

const Scratch = observer(() => {
  const [clickable, setClickable] = useState(false)
  const [buttonText, setButtonText] = useState(
    '화면을 손으로 문질러서 뽑기를 긁어주세요!',
  )
  const containerRef = useRef()

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )
  }, [])

  const content = (
    <div
      ref={containerRef}
      id='friendCleanContainer'
      className='relative items-center justify-center rounded-lg default'
    >
      {/* <img
        className='w-[70%] items-center flex mb-6 z-10'
        alt='box'
        src='/images/treasureHunt/scratch/title.png'
      /> */}
      <div className='absolute -top-[12vw] flex font-bold text-[8vw] text-[#fff] mb-6 z-10'>
        행운뽑기
      </div>

      <ScratchCard
        width={containerRef.current?.clientWidth}
        height={containerRef.current?.clientWidth * (186 / 307) ?? 200}
        className='m-0'
        image='/images/treasureHunt/scratch/notScratched.png'
        style={{
          borderRadius: '8px',
          padding: 0,
          margin: 0,
        }}
        brushSize={0}
        containerId='friendCleanContainer'
        ratio={186 / 307}
        finishPercent={70}
        onComplete={() => {
          setClickable(true)
          if (THModalStore?.config?.reward > 0) {
            setButtonText('뽑기 보상 받기')
          } else {
            setButtonText('닫기')
          }
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'allowiPhoneGesture',
              boolean: true,
            }),
          )
        }}
        // customBrush={{
        //   image: '../images/treasureHunt/scratch/brush.png',
        //   width: 60,
        //   height: 60,
        // }}
      >
        <div className='flex w-full h-full'>
          <img
            className='absolute w-full h-full'
            alt='clean'
            src='/images/treasureHunt/scratch/scratched.png'
          />
          <div className='absolute w-full h-full'>
            <div className='flex flex-row w-full h-full rounded-lg'>
              <div className='flex flex-col items-center justify-center h-full '>
                <div className='pl-4 w-[34vw] flex justify-center items-center'>
                  <img
                    className='w-[16vw] h-[16vw]'
                    src={`../images/treasureHunt/emoji/animal/${
                      THModalStore?.config?.winningNumber ??
                      utility.random(0, 17)
                    }.png`}
                    alt='character'
                  />
                </div>
              </div>
              <div className='flex flex-col items-center justify-start flex-1 h-full'>
                <div className='flex flex-row items-center justify-around w-full h-full pr-4 '>
                  <div className='relative'>
                    <img
                      className='w-[16vw] h-[16vw]'
                      src={`../images/treasureHunt/emoji/animal/${
                        THModalStore?.config?.myNumberOne ?? 18
                      }.png`}
                      alt='character'
                    />
                    <THImageText
                      fontSize={4}
                      className='w-full font-bold text-[#313138] text-center absolute -bottom-7 -left-1'
                      style={{
                        color: '#313138',
                      }}
                      text={`<point> ${THModalStore?.config?.rewardOne}`}
                    />
                  </div>
                  <div className='relative'>
                    <img
                      className='w-[16vw] h-[16vw]'
                      src={`../images/treasureHunt/emoji/animal/${
                        THModalStore?.config?.myNumberTwo ?? 19
                      }.png`}
                      alt='character'
                    />
                    <THImageText
                      fontSize={4}
                      className='w-full font-bold text-[#313138] text-center absolute -bottom-7 -left-1'
                      style={{
                        color: '#313138',
                      }}
                      text={`<point> ${THModalStore?.config?.rewardTwo}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScratchCard>
      {!clickable ? (
        <div className='w-full mt-2 py-2 text-white font-bold text-[5vw] text-center'>
          복권을 긁어서 확인해보세요!
        </div>
      ) : (
        <div className='w-full mt-2 py-2 text-white font-bold text-[5vw] text-center'>
          {THModalStore?.config?.reward > 0
            ? `${THModalStore?.config?.reward}원을 받았어요!`
            : '꽝.. 아쉬워요..'}
          <TreasureHuntButton
            className='w-[100%] mt-8 py-2'
            // secondTextImg={clickable && '../images/etc/heart.png'}
            secondText={buttonText}
            clickable={clickable}
            onClick={() => {
              if (clickable) {
                setClickable(false)
                THModalStore?.close()
                THUserStore.increase('dailyPoint', THModalStore?.config?.reward)
                THUserStore.increase('point', THModalStore?.config?.reward)
              }
            }}
          />
        </div>
      )}
    </div>
  )
  return (
    <ModalFrame
      style={{
        width: '90%',
        padding: 0,
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      content={content}
      className='w-[90%]'
      removeCloseButton={THUserStore?.currentFriend?.isTutorial}
    />
  )
})
const ScratchPurchase = observer((config) => {
  const content = (
    <div className='items-center justify-center default'>
      <div className='default font-bold text-center text-[6vw] mb-4'>
        행운뽑기 구매
      </div>
      <THImageText
        fontSize={4}
        style={{
          color: '#313138',
        }}
        text='구매 금액: <point> 5원'
      />
      <img
        alt='main'
        src='/images/treasureHunt/scratch/example.png'
        className={`w-full ${config?.imgClassName}`}
      />

      <div
        className={`default font-bold text-center text-[4vw] mt-2 ${config?.descriptionClassName}`}
      >
        <THImageText
          fontSize={4}
          style={{
            color: '#313138',
          }}
          text={`행운 그림과 나의 그림이 일치하면\n최대 <point> 1,000원을 받아요!`}
        />
      </div>
      <TreasureHuntButton
        className='w-full py-3 mt-6'
        clickable={THUserStore?.point >= 5}
        text='구매하기'
        onClick={() => {
          THModalStore?.close()
          TreasureHuntAction.purchaseScratchCard()
        }}
      />
    </div>
  )
  return <ModalFrame content={content} className='w-[80%]' />
})
const THModal = observer(() => {
  const [config, setConfig] = useState({})

  useEffect(() => {
    setConfig(THModalStore?.config)
  }, [THModalStore?.type, THModalStore?.key, THModalStore?.config])

  switch (THModalStore?.type) {
    case 'singlePageNotice':
      return <SinglePageNotice key={THModalStore.key} config={config} />
    case 'WatermelonProductDrawNotice':
      return (
        <WatermelonProductDrawNotice key={THModalStore.key} config={config} />
      )
    case 'WatermelonEnterDrawFinish':
      return (
        <WatermelonEnterDrawFinish key={THModalStore.key} config={config} />
      )
    case 'WatermelonEnterCoinShop':
      return <WatermelonEnterCoinShop key={THModalStore.key} config={config} />
    case 'watermelonChoseTicket':
      return <WatermelonChoseTicket key={THModalStore.key} config={config} />
    case 'watermelonRule':
      return <WatermelonRule key={THModalStore.key} config={config} />
    case 'watermelonReview':
      return <WatermelonReview key={THModalStore.key} config={config} />
    case 'watermelonCurrentRank':
      return <WatermelonCurrentRank key={THModalStore.key} config={config} />
    case 'serviceEndNotice':
      return <ServiceEndNotice key={THModalStore.key} config={config} />
    case 'energyMachine':
      return <EnergyMachine key={THModalStore.key} config={config} />
    case 'goldenEgg':
      return <GoldenEgg key={THModalStore.key} config={config} />
    case 'closetDraw':
      return <ClosetDraw key={THModalStore.key} config={config} />
    case 'scratch':
      return <Scratch key={THModalStore.key} config={config} />
    case 'scratchPurchase':
      return <ScratchPurchase key={THModalStore.key} config={config} />
    case 'searchButtonEnforce':
      return <SearchButtonEnforce key={THModalStore.key} config={config} />
    case 'uploadChallenge':
      return <UploadChallenge key={THModalStore.key} config={config} />
    case 'diceEvent':
      return <DiceEvent key={THModalStore.key} config={config} />
    case 'confirm':
      return <Confirm key={THModalStore.key} config={config} />
    case 'friendDelete':
      return <FriendDelete key={THModalStore.key} config={config} />
    case 'linkShare':
      return <LinkShare key={THModalStore.key} config={config} />
    case 'searchNotice':
      return <SearchNotice key={THModalStore.key} config={config} />
    case 'dailyPointBonus':
      return <DailyPointBonus key={THModalStore.key} config={config} />
    case 'reward':
      return <Reward key={THModalStore.key} config={config} />
    case 'infoList':
      return <InfoList key={THModalStore.key} config={config} />
    case 'toonModal':
      return (
        <ModalFrame
          content={<ToonModal config={config} />}
          className='w-[70%]'
          style={{ padding: 0, borderRadius: 0 }}
          removeCloseButton
        />
      )
    case 'applePang':
      return (
        <ModalFrame
          content={<ApplePangModal config={config} />}
          className='w-[90%]'
        />
      )
    case 'applePangRank':
      return (
        <ModalFrame
          content={<ApplePangRankModal config={config} />}
          className='w-[90%]'
        />
      )
    case 'eggMoneyTradeOrderConfirm':
      return (
        <ModalFrame
          content={<EggTradeOrderConfirmModal config={config} />}
          className='w-[90%]'
        />
      )

    case 'eggMoneyBasicModal':
      return (
        <ModalFrame
          content={<EggMoneyBasicModal config={config} />}
          className='w-[90%]'
        />
      )

    case 'eggMoneyCancelOrderConfirm':
      return (
        <ModalFrame
          content={<EggTradeCancelOrderConfirmModal config={config} />}
          className='w-[90%]'
        />
      )

    case 'eggMoneyTradeOrderSuccess':
      return (
        <ModalFrame
          content={<EggMoneyOrderCompleteModal config={config} />}
          className='w-[90%]'
        />
      )

    case 'eggMoneyBoxDetail':
      return (
        <ModalFrame
          content={<EggMoneyBoxDetail config={config} />}
          className='w-[90%]'
        />
      )

    case 'eggMoneyKakaoShare':
      return (
        <ModalFrame
          content={<EggMoneyKakaoShare config={config} />}
          className='w-[90%]'
        />
      )

    case 'eggMoneyTutorialModal':
      return (
        <ModalFrame
          content={<EggMoneyTutorialModal config={config} />}
          className='w-[90%]'
        />
      )

    case 'eggMoneyInviteModal':
      return (
        <ModalFrame
          content={<EggMoneyInviteModal config={config} />}
          className='w-[90%]'
        />
      )
    case 'eggMoneyRouletteModal':
      return (
        <ModalFrame
          content={<EggMoneyRouletteModal config={config} />}
          className='w-[90%]'
        />
      )

    default:
      return null
  }
})

export default THModal
