import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import ToastStore from '../../stores/ToastStore'
import backendApis from '../../utils/backendApis.ts'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const TimeSaleEventPage = observer(({ eventInfo, toonsInfo }) => {
  const { push } = useMyFlow()

  const sortedToons = toonsInfo?.sort((a, b) => {
    return (
      new Date(a.promotionInfo?.timeSale?.eventStartedAt) -
      new Date(b.promotionInfo?.timeSale?.eventStartedAt)
    )
  })

  const [currentIndex, setCurrentIndex] = useState(() => {
    const now = moment().format('YYYY-MM-DD')
    const index = sortedToons?.findIndex((toon) => {
      const startDay = moment(
        toon?.promotionInfo?.timeSale?.eventStartedAt,
      ).format('YYYY-MM-DD')
      return now === startDay
    })

    return index > 0 ? index : 0
  })
  const [isAlarmSet, setIsAlarmSet] = useState(
    !!window.localStorage.getItem(`timesale_alarm_${eventInfo?._id}`),
  )

  const containerRef = useRef(null)

  const handleScroll = (e) => {
    // 스크롤 중에는 새로운 인덱스 계산을 하지 않음
    if (isScrolling.current) return

    const container = e.target
    const scrollPosition = container.scrollLeft
    const itemWidth = window.innerWidth * 0.6 + 16
    const newIndex = Math.round(scrollPosition / itemWidth)

    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex)

      // 스크롤 스냅 동작 추가
      isScrolling.current = true
      const targetScroll = newIndex * itemWidth

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth',
      })

      // 스크롤 애니메이션이 끝나면 플래그 해제
      setTimeout(() => {
        isScrolling.current = false
      }, 100) // 스크롤 애니메이션 시간과 맞춤
    }
  }

  // 스크롤 중인지 추적하는 ref 추가
  const isScrolling = useRef(false)

  // 초기 자동 스크롤 추가
  useEffect(() => {
    if (containerRef.current && currentIndex > 0) {
      const itemWidth = window.innerWidth * 0.6 + 16
      const targetScroll = currentIndex * itemWidth

      // DOM이 완전히 렌더링된 후 스크롤 실행
      setTimeout(() => {
        if (containerRef?.current) {
          containerRef.current.scrollTo({
            left: targetScroll,
            behavior: 'instant', // 초기 스크롤은 즉시 이동
          })
        }
      }, 100)
    }
  }, [currentIndex])

  const getDayOfWeek = (date) => {
    const days = ['일', '월', '화', '수', '목', '금', '토']
    return days[new Date(date).getDay()]
  }
  const formatDateToKorean = (date) => {
    const d = new Date(date)
    const today = new Date()

    // 날짜가 오늘인지 확인
    if (
      d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear()
    ) {
      return '오늘'
    }

    const days = [
      '일요일',
      '월요일',
      '화요일',
      '수요일',
      '목요일',
      '금요일',
      '토요일',
    ]

    return `${d.getMonth() + 1}월 ${d.getDate()}일 ${days[d.getDay()]}`
  }

  const getOverlayContent = (toon) => {
    const now = new Date()
    const startDate = new Date(toon?.promotionInfo?.timeSale?.eventStartedAt)
    const endDate = new Date(toon?.promotionInfo?.timeSale?.eventEndedAt)

    if (now > startDate && now < endDate) {
      return null
    }

    if (now > endDate) {
      return (
        <div className='absolute inset-0 bg-black/70 flex items-center justify-center'>
          <span className='text-center text-white text-[5vw] font-bold'>
            종료된 <br /> 타임특가에요
          </span>
        </div>
      )
    }

    if (now < startDate) {
      return (
        <div className='absolute inset-0 bg-black/70 flex items-center justify-center'>
          <span className='text-center text-white text-[5vw] font-bold'>
            {formatDateToKorean(startDate)} <br />
            22시 오픈 예정
          </span>
        </div>
      )
    }

    return null
  }

  const getButtonContent = (toon, index) => {
    const now = new Date()
    const startDate = new Date(toon?.promotionInfo?.timeSale?.eventStartedAt)
    const endDate = new Date(toon?.promotionInfo?.timeSale?.eventEndedAt)

    if (now > endDate) {
      return (
        <button
          type='button'
          className='w-full mt-2 py-2 bg-gray-200 text-gray-600 rounded-lg text-[3.4vw] font-bold'
          disabled
        >
          타임특가 마감
        </button>
      )
    }

    if (now < startDate) {
      // Todo 특가 시작 전 알림받기 버튼 추가
      return (
        <button
          type='button'
          className='w-full mt-2 py-2 bg-gray-200 text-gray-600 rounded-lg text-[3.4vw] font-bold'
        >
          {/* 오픈 알림 받기 */}
          오픈 예정
        </button>
      )
    }

    return (
      <button
        type='button'
        className='w-full mt-2 py-2 bg-black text-white rounded-lg text-[3.4vw] font-bold'
      >
        보러가기
      </button>
    )
  }

  const TimerSection = () => {
    const [timeLeft, setTimeLeft] = useState({
      hours: '00',
      minutes: '00',
      seconds: '00',
    })

    useEffect(() => {
      const calculateTimeLeft = () => {
        const now = new Date()
        const targetTime = new Date('2025-01-25T22:00:00')

        // 현재 시간이 3월 25일 22시 이후라면 기존 로직 사용
        if (now >= targetTime) {
          const currentHour = now.getHours()
          targetTime.setTime(now.getTime())

          if (currentHour < 22) {
            targetTime.setHours(22, 0, 0, 0)
          } else {
            targetTime.setDate(targetTime.getDate() + 1)
            targetTime.setHours(22, 0, 0, 0)
          }
        }

        const difference = targetTime - now

        if (difference <= 0)
          return { hours: '00', minutes: '00', seconds: '00' }

        return {
          hours: String(Math.floor(difference / (1000 * 60 * 60))).padStart(
            2,
            '0',
          ),
          minutes: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
            2,
            '0',
          ),
          seconds: String(Math.floor((difference / 1000) % 60)).padStart(
            2,
            '0',
          ),
        }
      }

      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      return () => clearInterval(timer)
    }, [])

    return (
      <div className='flex flex-col justify-center items-center text-center pb-[6vw]'>
        <div className='relative bg-[#82C2FF] rounded-lg mt-[10vw]'>
          <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <div className='w-[60vw] text-[4.2vw] bg-white rounded-full py-[3vw] font-bold'>
              {new Date() < new Date('2025-01-25T22:00:00') ? (
                <>이벤트 시작까지</>
              ) : (
                <>
                  오늘의 <span className='text-[#50A7FA]'>할인 종료까지</span>
                </>
              )}
            </div>
          </div>
          <div className='w-[80vw] flex flex-col justify-center items-center py-[5vw] gap-[2vw] text-white border-[0.6vw] border-[#50A7FA] rounded-lg'>
            <div className='flex justify-center items-center gap-[2vw]'>
              <div className='flex flex-col items-center'>
                <div className='bg-[#82C2FF] rounded-lg px-[3vw] pt-[2vw]'>
                  <span className='text-[6vw] font-bold'>{timeLeft.hours}</span>
                </div>
                <span className='text-[3vw]'>시</span>
              </div>
              <span className='text-[6vw] font-bold mt-[-2vw]'>:</span>
              <div className='flex flex-col items-center'>
                <div className='bg-[#82C2FF] rounded-lg px-[3vw] pt-[2vw]'>
                  <span className='text-[6vw] font-bold'>
                    {timeLeft.minutes}
                  </span>
                </div>
                <span className='text-[3vw]'>분</span>
              </div>
              <span className='text-[6vw] font-bold mt-[-2vw]'>:</span>
              <div className='flex flex-col items-center'>
                <div className='bg-[#82C2FF] rounded-lg px-[3vw] pt-[2vw]'>
                  <span className='text-[6vw] font-bold'>
                    {timeLeft.seconds}
                  </span>
                </div>
                <span className='text-[3vw]'>초</span>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-[4vw] text-[3vw] text-gray-800'>
          * 매일 오후 10시, 새로운 작품 등장!
        </div>
      </div>
    )
  }

  return (
    <div className='py-[6vw] bg-[#E5F2FF]'>
      <TimerSection />

      <div
        ref={containerRef}
        className='overflow-x-auto scrollbar-hide pb-[4vw]'
        style={{
          scrollSnapType: 'x mandatory',
          scrollPadding: '0 20%',
          WebkitOverflowScrolling: 'touch',
          scrollBehavior: 'smooth', // 부드러운 스크롤 추가
        }}
        onScroll={handleScroll}
      >
        <div className='inline-flex gap-4 px-[20%]'>
          {sortedToons?.map((toon, index) => {
            const eventDate = moment(
              toon?.promotionInfo?.timeSale?.eventStartedAt,
            )
            const dateText = `${eventDate.format('DD')}. ${getDayOfWeek(
              eventDate,
            )}`

            return (
              <div
                key={toon?._id}
                className='w-[60vw] flex-shrink-0 cursor-pointer transition-all duration-300 ease-in-out'
                style={{
                  scrollSnapAlign: 'center',
                  scrollSnapStop: 'always', // 한 번에 한 항목씩만 스크롤
                  transform: `scale(${currentIndex === index ? 1 : 0.9})`,
                  opacity: currentIndex === index ? 1 : 0.7,
                }}
                onClick={() => {
                  // Todo : 성인 인증 확인이 안 됨. MainToonComponent로 이관을 고려
                  push('AltoonDetailPage', {
                    toonId: toon?._id,
                    component: 'AltoonEventPage_timeSale',
                  })
                }}
              >
                <div className='bg-white rounded-xl overflow-hidden shadow-md border border-gray-100'>
                  <div className='relative'>
                    <img
                      src={toon?.thumbnail}
                      alt={toon?.title}
                      className='w-full aspect-[3/4] object-cover'
                    />
                    <div className='absolute top-2 right-2 bg-[#FF3E3E] text-white px-2 py-1 rounded-md text-[3.2vw] font-bold'>
                      {toon?.promotionInfo?.timeSale?.discountRate || '??'}%
                    </div>
                    {getOverlayContent(toon)}
                  </div>
                  <div className='p-3'>
                    {toon?.promotionInfo?.timeSale?.eventStartedAt && (
                      <div className='w-[14vw] px-[2vw] py-[1vw] mb-[2vw] bg-black rounded-full text-center text-[3.2vw] text-white font-bold'>
                        {dateText}
                      </div>
                    )}
                    <div className='font-bold text-[3.8vw] mb-1 truncate'>
                      {toon?.title}
                    </div>
                    <div className='flex items-end gap-1'>
                      <span className='text-[3.6vw] text-gray-400 line-through'>
                        {toon.toonPrice}코인
                      </span>
                      <span className='text-[5vw] text-red-500 font-bold'>
                        {toon?.promotionInfo?.timeSale?.salePrice || '???'}
                        코인
                      </span>
                    </div>
                    {getButtonContent(toon, index)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* //Todo 특가 시작 전 알림받기 버튼 추가 */}
      {/* <button
        type='button'
        className={
          isAlarmSet
            ? 'flex items-center justify-center gap-[2vw] ml-[20vw] w-[60vw] mt-4 py-3 border rounded-full text-[4.2vw] font-bold text-gray-400'
            : 'flex items-center justify-center gap-[2vw] ml-[20vw] w-[60vw] mt-4 py-3 border-black border-[0.6vw] rounded-full text-[4.2vw] font-bold bg-white'
        }
        disabled={isAlarmSet}
        onClick={async () => {
          const alarmSet = await backendApis.setToonAlarm('timesale')
          if (alarmSet?.status === 200) {
            ToastStore.toastOn({
              type: 'emoji',
              emoji: '🔔',
              message: '타임특가 10분 전에 알려드릴게요!',
              duration: 2000,
            })
            setIsAlarmSet(true)
            window.localStorage.setItem(
              `timesale_alarm_${eventInfo?._id}`,
              'true',
            )
          } else {
            ToastStore.toastOn({
              type: 'error',
              message:
                '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
              duration: 2000,
            })
          }
        }}
      >
        {isAlarmSet ? (
          <div>알림받는 중</div>
        ) : (
          <>
            <img
              src='/images/toon/eventPage/alarmBell.png'
              alt='alarm'
              className='w-[6vw] h-[6vw]'
            />
            <div>특가 시작 전 알림받기</div>
          </>
        )}
      </button> */}
    </div>
  )
})

export default TimeSaleEventPage
