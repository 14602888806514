import { AppScreen } from '@stackflow/plugin-basic-ui'
import { useActivity } from '@stackflow/react'
import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
} from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'
import { BottomSheet } from 'react-spring-bottom-sheet'

import AltoonAction from '../actions/AltoonAction'
import AlwayzImage from '../components/atoms/AlwayzImage'
import ToonModal from '../components/templates/ToonModal'
import AltoonTagComponent from '../components/toon/AltoonTagComponent'
import RecommendRelatedToonsComponent from '../components/toon/RecommendRelatedToonsComponent'
import THImageText from '../components/treasureHunt/THImageText'
import TreasureHuntButton from '../components/treasureHunt/TreasureHuntButton'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import AB from '../utils/ab'
import AB_V3 from '../utils/ab_v3'
import backendApis from '../utils/backendApis.ts'
import resize from '../utils/resize'

const AltoonDetailPage = observer(({ params }) => {
  const activity = useActivity()
  const { pop, push } = useMyFlow()
  const toonId = params.toonId

  const rentPeriod = 3
  const scrollContainerRef = useRef(null)
  const waitForFreeToon =
    moment().isAfter(
      moment(AltoonUserStore?.currentViewLog?.waitForFreeInfo?.eventStartedAt),
    ) &&
    moment().isBefore(
      moment(AltoonUserStore?.currentViewLog?.waitForFreeInfo?.eventEndedAt),
    )
  const waitForFreePossible =
    AltoonUserStore?.currentViewLog?.waitForFreeAvailable === true &&
    waitForFreeToon

  const [isLoading, setIsLoading] = useState(false)
  const [toonInfo, setToonInfo] = useState({})
  const [chapterInfo, setChapterInfo] = useState([])
  const [paidChapterCount, setPaidChapterCount] = useState()
  const [toonPrice, setToonPrice] = useState(300)
  const [previewDays, setPreviewDays] = useState()
  const [nextChapter, setNextChapter] = useState()
  const [viewedChapterData, setViewedChapterData] = useState([])
  const [showPreviewChapter, setShowPreviewChapter] = useState(false)
  const [openTicketModal, setOpenTicketModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])
  const [openBottomSheet, setOpenBottomSheet] = useState(false)
  const [showBulkPurchaseButton, setShowBulkPurchaseButton] = useState(false)
  const [bulkPurchaseComplete, setBulkPurchaseComplete] = useState(false)
  const [chapterAscendingSort, setChapterAscendingSort] = useState(false)
  const [selectedTab, setSelectedTab] = useState('allChapters')

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab) // 새로운 탭을 상태에 저장
  }

  const dayNameGenerator = (date) => {
    switch (date) {
      case 'Mon':
        return '월'
      case 'Tue':
        return '화'
      case 'Wed':
        return '수'
      case 'Thu':
        return '목'
      case 'Fri':
        return '금'
      case 'Sat':
        return '토'
      case 'Sun':
        return '일'
      default:
        return '월'
    }
  }

  const navigateToReaderPage = ({
    chapter,
    episode = chapter?.episode,
    component = 'detailPage',
    index,
  }) => {
    push('AltoonReaderPage', {
      state: {
        chapterInfo: chapter,
        toonInfo,
        episode,
        rentPeriod,
      },
      component,
      index,
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      if (!toonId || toonId === 'undefined') {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
        return
      }
      const result = await backendApis.loadToonDetail(toonId)
      const toon = result?.data[0]

      if (result?.status === 200) {
        const viewLog = await backendApis.loadToonViewLog(toonId)
        const chapterList = await backendApis.loadToonChapterInfo(
          toonId,
          !viewLog?.data?.viewedChapterIdList ? true : chapterAscendingSort,
        )
        const nextChapterInfo = await backendApis.loadChapterByEpisode(
          toonId,
          viewLog?.data?.lastViewedEpisode,
        )

        AltoonUserStore.set('currentViewLog', viewLog?.data)
        setViewedChapterData(viewLog?.data?.viewedChapterIdList || [])
        setNextChapter(nextChapterInfo?.data)

        setToonInfo(result?.data[0])
        setToonPrice(result?.data[0]?.toonPrice)

        // 미리보기 없는 연재작 위해 수정
        if (
          result?.data[0]?.previewDays !== undefined &&
          result?.data[0]?.previewDays !== null
        ) {
          setPreviewDays(result?.data[0]?.previewDays)
        } else {
          setPreviewDays(8)
        }

        setChapterInfo(chapterList?.data)

        setPaidChapterCount(
          chapterList?.data?.filter((chapter) => !chapter.isFree)?.length,
        )
        if (
          result?.data[0]?.promotionInfo?.bulkPurchase?.eventStartedAt &&
          result?.data[0]?.promotionInfo?.bulkPurchase?.eventEndedAt
        ) {
          if (
            // 프로모션 기간이고,
            moment().isAfter(
              result?.data[0]?.promotionInfo?.bulkPurchase?.eventStartedAt,
            ) &&
            moment().isBefore(
              result?.data[0]?.promotionInfo?.bulkPurchase?.eventEndedAt,
            ) &&
            // 전권 대여 기간이 끝났다면
            (!viewLog?.data?.lastBulkPurchasedAt ||
              moment().isAfter(
                moment(viewLog?.data?.lastBulkPurchasedAt).add(
                  rentPeriod,
                  'days',
                ),
              ))
          ) {
            setShowBulkPurchaseButton(true)
          }
        }

        AltoonUserStore.set('currentToonChapterInfo', chapterList?.data)
        AltoonUserStore.set('currentToonPrice', result?.data[0]?.toonPrice)
        await backendApis.logToonAction('AltoonDetailPage', 'enteredScreen', {
          toonId,
        })

        const savedPosition = window.localStorage.getItem(
          `detailPage_scrollPosition_${toonId}`,
        )
        if (
          savedPosition &&
          scrollContainerRef.current &&
          activity.name === 'AltoonDetailPage'
        ) {
          setTimeout(() => {
            scrollContainerRef.current.scrollTop = parseInt(savedPosition, 10)
          }, 100)
        }
      } else {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
      }

      setIsLoading(false)
    }

    try {
      if (
        window.ReactNativeWebView &&
        typeof window.ReactNativeWebView.postMessage === 'function'
      ) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'allowiPhoneGesture',
            boolean: false,
          }),
        )
      }
    } catch (error) {
      console.warn('ReactNativeWebView bridge error:', error)
    }

    handleTabChange('allChapters')
    fetchData()
  }, [toonId, bulkPurchaseComplete, chapterAscendingSort, activity])

  useEffect(() => {
    const handleScroll = (e) => {
      // 스크롤 위치 기억하는 용도
      window.localStorage.setItem(
        `detailPage_scrollPosition_${toonId}`,
        e.target.scrollTop,
      )
    }

    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 bg-[#ffffffe0] w-full z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          type='button'
          aria-label='back'
          className='mx-2 p-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <button
          type='button'
          className='px-[4vw] text-[3.6vw]'
          onClick={() => {
            backendApis.logToonAction('AltoonDetailPage', 'linkShare', '')

            AltoonAction.linkShare({
              shareType: 'Altoon_toonShare',
              title: `[올툰]`,
              description: `[${
                toonInfo?.title?.length > 20
                  ? `${toonInfo?.title?.slice(0, 20)}...`
                  : toonInfo?.title
              }] 작품을 추천해요!`,
              imageUrl: toonInfo?.thumbnail,
              payload: {
                toonId,
              },
            })
          }}
        >
          <img
            src='/images/toon/shareIcon.png'
            alt='share'
            className='w-[5vw] h-[5vw]'
          />
        </button>
      </section>
    )
  }

  const ToonInfoSection = () => {
    return (
      <div className='flex flex-col items-center'>
        <div className='flex flex-row items-center justify-center rounded-lg'>
          <AlwayzImage
            src={resize(toonInfo?.thumbnail, 234, 328, 'fill')}
            alt={toonInfo?.title}
            className='w-[40vw] h-[52vw] rounded-lg flex-shrink-0'
            lazy
          />
          <div className='w-full h-[52vw] flex flex-col pl-2'>
            {toonInfo?.bannerImage && (
              <img
                src={toonInfo?.bannerImage}
                alt={toonInfo?.title}
                className='w-full h-[16vh] rounded-t-lg'
              />
            )}
            <div className='p-2 text-xs'>
              <div className='text-lg font-semibold'>{toonInfo?.title}</div>
              <div className='flex flex-row pb-[4vw]'>
                <div>{toonInfo?.author}</div>
                {toonInfo?.weekday && (
                  <div className='text-[#999999] flex flex-row'>
                    <div className='mx-2'>|</div>
                    {toonInfo?.weekday?.map((day, index) => {
                      const dayString = dayNameGenerator(day)
                      return index === toonInfo.weekday.length - 1
                        ? `${dayString} 연재`
                        : `${dayString}, `
                    })}
                  </div>
                )}
              </div>

              <div className='pb-[4vw] text-[2.8vw]'>
                <button
                  type='button'
                  className='flexRow items-start text-gray-500 text-start max-h-[8vw] overflow-clip'
                  onClick={() => {
                    setModalProp({ toonInfo })
                    setModalType('toonDescription')
                    setOpenTicketModal(true)
                  }}
                >
                  <THImageText
                    text={
                      toonInfo?.description?.length > 20
                        ? `${toonInfo?.description
                            ?.slice(0, 20)
                            ?.replace(/\\n/g, '\n')}..`
                        : toonInfo?.description?.replace(/\\n/g, '\n')
                    }
                  />

                  {toonInfo?.description?.length > 20 && (
                    <img
                      src='/images/toon/ic_detail_arrow.png'
                      alt='arrowDown'
                      className='w-[3.6vw] h-[3.6vw] ml-1'
                    />
                  )}
                </button>
              </div>
              <div className='pb-[2vw] flex flex-row text-[2.4vw] overflow-scroll flex-wrap'>
                {toonInfo?.tagList?.hot === true && (
                  <AltoonTagComponent
                    key={Math.random()}
                    tag='hot'
                    type='detailPage'
                  />
                )}
                {toonInfo?.tagList?.new && (
                  <AltoonTagComponent
                    key={Math.random()}
                    tag='new'
                    type='detailPage'
                  />
                )}
                {toonInfo?.tagList?.mainGenre?.map((tag) => (
                  <AltoonTagComponent
                    key={Math.random()}
                    tag={tag}
                    type='detailPage'
                  />
                ))}
                {toonInfo?.tagList?.subGenre?.slice(0, 5).map((tag) => (
                  <AltoonTagComponent
                    key={Math.random()}
                    tag={tag}
                    type='detailPage'
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const RelatedToonsTabSection = ({ onTabChange }) => {
    const [selectedType, setSelectedType] = useState(selectedTab)
    const [borderStyle, setBorderStyle] = useState({})

    const allChaptersRef = useRef(null)
    const similarRef = useRef(null)

    // 픽셀(px) 값을 뷰포트 너비(vw)로 변환하는 함수
    const pxToVw = (px) => (px / window.innerWidth) * 100

    // 선택된 탭에 따라 빨간 막대기 위치 및 너비 조정
    useEffect(() => {
      let activeRef
      switch (selectedType) {
        case 'allChapters':
          activeRef = allChaptersRef
          break
        case 'similar':
          activeRef = similarRef
          break
        default:
          break
      }

      if (activeRef && activeRef.current) {
        const rect = activeRef.current.getBoundingClientRect()
        setBorderStyle({
          width: `${pxToVw(rect.width) - 3}vw`, // 가로 너비를 vw로 설정
          left: `${pxToVw(rect.left) - 3}vw`, // 좌측으로 3vw 이동
        })
      }
    }, [selectedType])

    const getButtonStyle = (type) => {
      if (type === selectedType) {
        return 'px-4 text-[#E4443B]' // 선택된 탭 스타일
      }
      return 'px-4 text-gray-500' // 기본 스타일
    }

    const handleTabClick = (type) => {
      if (type === 'similar') {
        window.localStorage.setItem('relatedToonsTabPing', 'true')
      }
      setSelectedType(type) // 애니메이션을 위한 로컬 상태 변경
      setTimeout(() => {
        onTabChange(type) // 애니메이션 완료 후 부모에게 탭 변경 알림
      }, 300) // 애니메이션의 지속 시간과 동일하게 맞춤
    }

    const ping = window.localStorage.getItem('relatedToonsTabPing')

    return (
      <div className='w-full py-[4vw] flex flex-row justify-between items-center text-[4.2vw] font-normal'>
        {/* 전체회차/비슷한작품 탭 */}
        <div className='w-full flex flex-row justify-between px-10 py-2 text-[4.2vw] border-b border-[#F3F3F3] relative'>
          <div
            style={borderStyle}
            className='absolute -bottom-[0.3vh] h-[0.4vh] bg-[#E4443B] transition-all duration-300'
          />
          <button
            ref={allChaptersRef}
            type='button'
            className={getButtonStyle('allChapters')}
            onClick={() => handleTabClick('allChapters')}
          >
            전체 회차
          </button>
          <button
            ref={similarRef}
            type='button'
            className={getButtonStyle('similar')}
            onClick={() => handleTabClick('similar')}
          >
            비슷한 작품
            {/* // ping 달아주기 */}
            {!ping && (
              <div className='absolute w-[1.5vw] h-[1.5vw] bg-[#E4443B] rounded-full inline-block ml-1 animate-pulse' />
            )}
          </button>
        </div>
      </div>
    )
  }

  const ChapterCountingSection = () => {
    return (
      <>
        {viewedChapterData?.length > 0 && (
          <div className='w-full flex justify-between items-center'>
            <div className='text-left'>
              전체 {chapterInfo?.length}화{' '}
              <span className='text-[#FF3E3E] ml-[1vw] text-[3.4vw] font-normal'>
                무료{' '}
                {
                  chapterInfo.filter((chapter) => chapter.isFree === true)
                    .length
                }
                화
              </span>
            </div>

            {/* 정렬버튼 */}
            <button
              type='button'
              className='flex items-center'
              onClick={() => {
                setChapterAscendingSort(!chapterAscendingSort)
              }}
            >
              <img
                src='/images/toon/sortingIcon.png'
                alt='toonCoin'
                className='w-[4vw] h-[4vw] mx-1'
              />
              <div className='text-[3vw] text-[#BCBDC3]'>
                {chapterAscendingSort ? '최신순' : '회차순'}
              </div>
            </button>
          </div>
        )}
      </>
    )
  }

  // 첫화보기 또는 이어보기 버튼
  const FirstChapterButton = () => {
    const chapterCount = chapterInfo?.length

    return (
      <div
        className={`flex justify-center w-full fixed bottom-0 z-10 bg-white py-[3vw] `}
      >
        <button
          type='button'
          className='flexRow justify-center items-center w-[90vw] h-[12vw] rounded bg-[#EB4F47] text-white text-[4vw] font-semibold'
          onClick={() => {
            if (viewedChapterData?.length > 0 && nextChapter) {
              // 아직 미리보기를 할 수 없음. 오늘이 공개일 + 미리보기 기간보다 이전인 경우
              if (
                moment().isSameOrBefore(
                  moment(nextChapter?.publishedAt).subtract(
                    previewDays,
                    'days',
                  ),
                )
              ) {
                navigateToReaderPage({
                  chapter: chapterInfo[chapterCount - 1],
                  episode: 1,
                  component: 'detailPage_firstChapterButton',
                })
              }
              // 다음회차가 유료 or 미리보기가 가능한 경우 => 돈 내야하는 경우
              else if (
                nextChapter?.isFree === false ||
                moment().isBefore(nextChapter?.publishedAt)
              ) {
                const viewedChapter = viewedChapterData?.find(
                  (item) => item.chapterId.toString() === nextChapter?._id,
                )
                // 대여 후 미리보기가 가능한 챕터를 확인
                const waitForFreeChapter =
                  waitForFreePossible &&
                  chapterInfo?.filter((chapter) =>
                    moment().isAfter(chapter?.publishedAt),
                  )?.length -
                    AltoonUserStore?.currentViewLog?.waitForFreeInfo
                      ?.recentChapterLimit >=
                    nextChapter?.episode
                // 아직 해당 챕터를 대여하지 않은 경우
                if (!viewedChapter) {
                  // 대기 후 무료 이용 가능한 챕터라면
                  if (waitForFreeChapter) {
                    setModalType('useWaitForFree')
                    setModalProp({
                      chapter: nextChapter,
                      toonInfo,
                      viewLog: AltoonUserStore?.currentViewLog,
                      isWaitForFreeLimitedChapter:
                        chapterInfo?.filter((chapter) =>
                          moment().isAfter(chapter?.publishedAt),
                        )?.length -
                          AltoonUserStore?.currentViewLog?.waitForFreeInfo
                            ?.recentChapterLimit <
                        nextChapter.episode,
                      episode: nextChapter?.episode,
                      rentPeriod,
                    })
                    setOpenTicketModal(true)
                  }
                  // 대여 코인이 충분한 경우
                  else if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                    setModalType('useCoin')
                    setModalProp({
                      chapter: nextChapter,
                      toonInfo,
                      episode: nextChapter?.episode,
                      rentPeriod,
                    })
                    setOpenTicketModal(true)
                  }
                  // 대여 코인이 부족한 경우
                  else {
                    setModalType('lackCoin')
                    setModalProp({ toonInfo })
                    setOpenTicketModal(true)
                  }
                }
                // 이미 해당 챕터를 대여한 경우
                else {
                  const daysSinceFirstViewed = moment()
                    .startOf('day')
                    .diff(
                      moment(viewedChapter.firstViewedAt).startOf('day'),
                      'days',
                    )
                  // 대여 기간이 만료된 경우
                  if (daysSinceFirstViewed > rentPeriod) {
                    setModalType('rentExpired')
                    setModalProp({
                      chapter: nextChapter,
                      toonInfo,
                      episode: nextChapter?.episode,
                      rentPeriod,
                    })
                    setOpenTicketModal(true)
                  }
                  // 대여 기간이 남아있다면 이어보기로 이동
                  else {
                    navigateToReaderPage({
                      chapter: nextChapter,
                      episode: nextChapter?.episode,
                      component: 'detailPage_firstChapterButton',
                    })
                  }
                }
              }
              // 그 외의 경우 바로 다음 챕터로 이동
              else {
                navigateToReaderPage({
                  chapter: nextChapter,
                  episode: nextChapter?.episode,
                  component: 'detailPage_firstChapterButton',
                })
              }
            }
            // 만약 현재 읽은 에피소드가 없다면 첫 화로 이동
            else if (chapterInfo && chapterInfo.length > 0) {
              push('AltoonReaderPage', {
                state: {
                  chapterInfo: chapterInfo[0],
                  toonInfo,
                  episode: 1,
                  rentPeriod,
                },
                replace: false,
              })
            } else {
              // chapterInfo가 없는 경우 에러 처리
              ToastStore.toastOn({
                type: 'error',
                message:
                  '회차 정보를 불러올 수 없습니다.\n잠시 후 다시 시도해주세요.',
                duration: 2000,
              })
            }
          }}
        >
          {/* 버튼에 표시되는 텍스트 */}
          {viewedChapterData?.length > 0 &&
          nextChapter &&
          moment().isAfter(
            moment(nextChapter?.publishedAt).subtract(previewDays, 'days'),
          )
            ? `"${
                nextChapter?.chapterTitle?.length > 9
                  ? `${nextChapter?.chapterTitle?.slice(0, 9)}..`
                  : nextChapter?.chapterTitle
              }" 이어보기`
            : '첫 화 보기'}
          <FiChevronRight className='w-4 h-4 inline-block' />
        </button>
      </div>
    )
  }

  const BulkPurchaseButton = () => {
    const chapterCount = chapterInfo?.length
    const totalPrice = toonInfo?.promotionInfo?.bulkPurchase?.totalPrice || 0

    return (
      <button
        type='button'
        className='fixed bottom-[18vw] flex flex-row justify-between items-center w-full px-4 py-3 bg-gray-700 opacity-90 text-white text-[4vw] font-semibold z-20'
        onClick={async () => {
          setModalType('bulkPurchase')
          setModalProp({
            chapterCount,
            paidChapterCount,
            totalPrice,
            toonId,
            toonInfo,
            rentPeriod,
            handleLackCoin: async () => {
              setModalType('lackCoin')
              setModalProp({ toonInfo: modalProp?.toonInfo })
              setOpenTicketModal(true)
            },
            handleSuccess: async () => {
              if (
                AltoonUserStore?.userData?.toonCoin >=
                toonInfo?.promotionInfo?.bulkPurchase?.totalPrice
              ) {
                setIsLoading(true)
                const result = await backendApis.chapterBulkPurchase(
                  toonId,
                  rentPeriod,
                )
                if (result?.status === 200) {
                  AltoonUserStore?.increase(
                    'userData.toonCoin',
                    toonInfo?.promotionInfo?.bulkPurchase?.totalPrice * -1,
                  )

                  ToastStore.toastOn({
                    type: 'success',
                    message: '구매가 완료되었어요',
                    duration: 2000,
                  })
                  scrollContainerRef.current.scrollTop =
                    scrollContainerRef.current.scrollHeight
                  setBulkPurchaseComplete(true)
                } else {
                  ToastStore.toastOn({
                    type: 'error',
                    message: '구매에 실패했어요',
                    duration: 2000,
                  })
                }
                setIsLoading(false)
              }
            },
          })
          setOpenTicketModal(true)
        }}
      >
        <div>
          할인받고 전체 대여하기 (
          {moment(toonInfo?.promotionInfo?.bulkPurchase?.eventEndedAt).diff(
            moment(),
            'days',
          )}
          일 남음!)
        </div>
        <div className='px-4 py-2 rounded-full border-gray-300 border'>
          전체 대여
        </div>
      </button>
    )
  }

  const WeeklyNudge = () => {
    if (toonInfo?.weekday) {
      return (
        <div className='flexRow pt-[2vw] pb-[4vw] text-[3.6vw] whitespace-nowrap'>
          {toonInfo?.weekday?.length === 7 ? '매일 ' : '매주'}
          {toonInfo?.weekday?.length < 7 && (
            <div className='text-[#FF3E3E] font-semibold ml-1'>
              {toonInfo?.weekday?.map((day, index) => {
                const dayString = dayNameGenerator(day)
                return index === toonInfo.weekday.length - 1
                  ? `${dayString}요일마다 `
                  : `${dayString}, `
              })}
            </div>
          )}
          <div className='ml-1'>새로운 회차가 업데이트돼요</div>
        </div>
      )
    }
  }

  const GoodsCountComponent = () => {
    return (
      <div className='w-full py-[2vw] flexRow justify-between items-center'>
        {waitForFreeToon ? (
          <button
            type='button'
            className='w-[45vw] px-2 py-[3vw] flexRow items-center justify-between rounded bg-[#F6F6FA] text-[3.2vw] font-semibold'
            onClick={() => {
              setOpenBottomSheet(true)
            }}
          >
            <div>기다리면 무료</div>
            <div>
              {AltoonUserStore?.currentViewLog?.waitForFreeAvailable ? 1 : 0}회
            </div>
          </button>
        ) : (
          <div />
        )}
        <button
          type='button'
          className='w-[45vw] px-[4vw] py-[3vw] flexRow items-center justify-between rounded bg-[#F6F6FA] text-[3.2vw] font-semibold'
          onClick={() => {
            push('AltoonTicketPage', {
              component: 'detailPage_coinStatus',
            })
          }}
        >
          <div>내 코인 </div>
          <div className='flexRow'>
            <img
              src='/images/toon/toonCoin.png'
              alt='toonCoin'
              className='w-[4vw] h-[4vw] mx-1'
            />
            {commaNumber(
              AltoonUserStore?.userData?.toonCoin > 0
                ? AltoonUserStore?.userData?.toonCoin || 0
                : 0,
            )}
            C
          </div>
        </button>
      </div>
    )
  }

  const NoticeComponent = () => {
    return (
      <div className='w-full py-2 mt-2 mb-4 flexRow text-[3.8vw] bg-[#F6F6FA] font-semibold rounded'>
        <img
          src='/images/toon/megaphoneBlue.png'
          alt='megaphoneBlack'
          className='w-[4vw] h-[4vw] mr-2'
        />
        {toonInfo?.notice}
      </div>
    )
  }

  const SeolNoticeComponent = () => {
    return (
      <div className='w-full py-2 mb-2 flexRow text-[3.8vw] bg-[#F6F6FA] font-semibold rounded'>
        <img
          src='/images/toon/mainEventMission/bokIcon.png'
          alt='megaphoneBlack'
          className='w-[4vw] h-[4vw] mr-2'
        />
        설 연휴동안 정기 연재가 일시중단됩니다.
      </div>
    )
  }

  // 회차 목록
  const ChapterList = () => {
    return (
      <div className='pb-10'>
        {/* '0개의 미리보기가 있어요' 버튼: 사용자가 미리보기 가능한 회차가 있을 경우 표시 */}
        {(chapterInfo?.filter(
          (chapter) =>
            moment().isBefore(chapter?.publishedAt) &&
            moment().isAfter(
              moment(chapter?.publishedAt).subtract(previewDays, 'days'),
            ),
        )?.length > 0 ||
          AltoonUserStore?.userData?.isAdmin === true) && (
          <button
            type='button'
            className='px-2 py-3 w-full flex flex-row justify-between items-center border-y text-[3.8vw]'
            onClick={() => {
              setShowPreviewChapter(!showPreviewChapter) // 미리보기 회차 펼치기/접기 토글
              // 회차 클릭 이벤트
            }}
          >
            {/* 미리보기 회차들이 겹쳐 보이도록 표시 */}
            {!showPreviewChapter && (
              <div className='flex flex-row'>
                {chapterInfo
                  ?.filter(
                    (chapter) =>
                      moment().isBefore(chapter?.publishedAt) &&
                      moment().isAfter(
                        moment(chapter?.publishedAt).subtract(
                          previewDays,
                          'days',
                        ), // 미리보기 회차들
                      ),
                  )
                  .slice(0, 4)
                  .map((chapter) => (
                    <div
                      className='relative '
                      key={chapter.chapterTitle || chapter?.episode}
                      style={{ marginRight: '-2vw' }} // 이미지가 겹치게 하기 위한 스타일
                    >
                      <img
                        src={chapter.detailThumbnail}
                        alt={toonInfo?.title}
                        className='w-[8vw] h-[8vw] rounded-full'
                      />
                      {chapter?.onlyPaidCoin && (
                        <img
                          src='/images/toon/onlyPaidCoinIcon.png'
                          alt='ticket'
                          className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                        />
                      )}
                    </div>
                  ))}
              </div>
            )}
            {/* 미리보기 접기/펼치기 버튼 */}
            <div>
              {showPreviewChapter
                ? '미리보기 접기'
                : `${
                    chapterInfo?.filter(
                      (chapter) =>
                        moment().isBefore(chapter?.publishedAt) &&
                        moment().isAfter(
                          moment(chapter?.publishedAt).subtract(
                            previewDays,
                            'days',
                          ),
                        ),
                    )?.length
                  }개의 미리보기가 있어요`}
            </div>
            {!showPreviewChapter && (
              <FiChevronDown className='w-[7vw] h-[7vw] mr-2' />
            )}
            {showPreviewChapter && (
              <FiChevronUp className='w-[7vw] h-[7vw] mr-2' />
            )}
          </button>
        )}

        {/* 관리자 전용 전체회차 표시: 공개일 이전의 모든 회차를 표시함 */}
        {showPreviewChapter &&
          AltoonUserStore?.userData?.isAdmin === true &&
          chapterInfo
            ?.filter((chapter) => moment().isBefore(chapter?.publishedAt))
            .map((chapter) => (
              <button
                type='button'
                className={`my-2 flex flex-row items-center rounded-lg ${
                  viewedChapterData?.some(
                    (item) => item.chapterId.toString() === chapter._id,
                  )
                    ? 'bg-gray-100'
                    : 'bg-gray-200'
                }`}
                key={chapter.chapterTitle || chapter?.episode}
                onClick={() => {
                  const viewedChapter = viewedChapterData?.find(
                    (item) => item.chapterId.toString() === chapter._id,
                  )

                  if (!viewedChapter) {
                    if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                      setModalType('useCoin')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      setModalType('lackCoin')
                      setModalProp({ toonInfo })
                      setOpenTicketModal(true)
                    }
                  } else {
                    const daysSinceFirstViewed = moment()
                      .startOf('day')
                      .diff(
                        moment(viewedChapter.firstViewedAt).startOf('day'),
                        'days',
                      )
                    if (daysSinceFirstViewed > rentPeriod) {
                      setModalType('rentExpired')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      navigateToReaderPage({
                        chapter,
                        episode: chapter?.episode,
                        component: 'detailPage_adminSeeAll',
                      })
                    }
                  }
                }}
              >
                <div className='relative'>
                  <AlwayzImage
                    src={resize(
                      chapter.detailThumbnail,
                      toonInfo?.verticalThumbnail ? 360 : 234,
                      toonInfo?.verticalThumbnail ? 486 : 196,
                      'fill',
                    )}
                    alt={toonInfo?.title}
                    className={
                      toonInfo?.verticalThumbnail
                        ? 'w-[30vw] h-[43vw] rounded-lg resize-y self-stretch'
                        : 'w-[30vw] h-[25vw] rounded-lg resize-y self-stretch'
                    }
                    lazy
                  />
                  {chapter?.onlyPaidCoin && (
                    <img
                      src='/images/toon/onlyPaidCoinIcon.png'
                      alt='ticket'
                      className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                    />
                  )}
                  <img
                    src='/images/toon/previewIcon.png'
                    alt='previewIcon'
                    className='absolute top-[1vw] left-[1vw] w-[12vw] h-[4vw]'
                  />
                </div>
                <div className='p-4 w-[60vw] h-[12vh] flex flex-col items-start justify-center font-semibold text-[3.8vw]'>
                  <div
                    className={`text-start ${
                      viewedChapterData?.some(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                        ? 'text-gray-400'
                        : 'text-gray-600'
                    }`}
                  >
                    {chapter.chapterTitle}
                  </div>
                  <div
                    className={`pt-1 flex flex-row items-center text-[3vw] font-medium ${
                      viewedChapterData?.some(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                        ? 'text-gray-400'
                        : 'text-gray-500'
                    }`}
                  >
                    <div>
                      {moment(chapter.publishedAt)
                        .startOf('day')
                        .diff(moment().startOf('day'), 'days')}
                      일 후 무료
                    </div>
                    <div className='flex items-center ml-1'>
                      {!viewedChapterData?.includes(chapter._id) ? (
                        <>
                          <img
                            src='/images/toon/toonCoin.png'
                            alt='toonCoin'
                            className='w-[4vw] h-[4vw] ml-1'
                          />
                          <div className='ml-1'>{toonPrice}C</div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </button>
            ))}

        {/* 공개일 이전이지만 지정된 previewDays 이내인 미리보기 회차 표시 */}
        {/* (관리자뿐 아니라 일반 사용자도 볼 수 있는 미리보기 회차들) */}
        {showPreviewChapter &&
          chapterInfo
            ?.filter(
              (chapter) =>
                moment().isBefore(chapter?.publishedAt) &&
                moment().isAfter(
                  moment(chapter?.publishedAt).subtract(previewDays, 'days'),
                ),
            )
            .map((chapter) => (
              <button
                type='button'
                className={`relative my-2 flex flex-row items-center rounded-lg ${
                  viewedChapterData?.some(
                    (item) => item.chapterId.toString() === chapter._id,
                  )
                    ? 'bg-gray-100'
                    : 'bg-gray-200'
                }`}
                key={chapter.chapterTitle || chapter?.episode}
                onClick={() => {
                  const viewedChapter = viewedChapterData?.find(
                    (item) => item.chapterId.toString() === chapter._id,
                  )

                  if (!viewedChapter) {
                    if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                      setModalType('useCoin')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      setModalType('lackCoin')
                      setModalProp({ toonInfo })
                      setOpenTicketModal(true)
                    }
                  } else {
                    const daysSinceFirstViewed = moment()
                      .startOf('day')
                      .diff(
                        moment(viewedChapter.firstViewedAt).startOf('day'),
                        'days',
                      )
                    if (daysSinceFirstViewed > rentPeriod) {
                      setModalType('rentExpired')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      navigateToReaderPage({
                        chapter,
                        episode: chapter?.episode,
                        component: 'detailPage_previewChapter',
                      })
                    }
                  }
                }}
              >
                <div className='relative'>
                  <AlwayzImage
                    src={resize(
                      chapter.detailThumbnail,
                      toonInfo?.verticalThumbnail ? 360 : 234,
                      toonInfo?.verticalThumbnail ? 486 : 196,
                      'fill',
                    )}
                    alt={toonInfo?.title}
                    className={
                      toonInfo?.verticalThumbnail
                        ? 'w-[30vw] h-[43vw] rounded-lg resize-y self-stretch'
                        : 'w-[30vw] h-[25vw] rounded-lg resize-y self-stretch'
                    }
                    lazy
                  />
                  {chapter?.onlyPaidCoin && (
                    <img
                      src='/images/toon/onlyPaidCoinIcon.png'
                      alt='ticket'
                      className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                    />
                  )}
                  <img
                    src='/images/toon/previewIcon.png'
                    alt='ticket'
                    className='absolute top-[1vw] left-[1vw] w-[12vw] h-[4vw]'
                  />
                </div>
                <div className='p-4 w-[60vw] h-[12vh] flex flex-col items-start justify-center font-semibold text-[3.8vw]'>
                  <div
                    className={`text-start ${
                      viewedChapterData?.some(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                        ? 'text-gray-400'
                        : 'text-gray-600'
                    }`}
                  >
                    {chapter.chapterTitle}
                  </div>
                  <div
                    className={`pt-1 flex flex-row items-center text-[3vw] font-medium ${
                      viewedChapterData?.some(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                        ? 'text-gray-400'
                        : 'text-gray-500'
                    }`}
                  >
                    <div>
                      {moment(chapter.publishedAt)
                        .startOf('day')
                        .diff(moment().startOf('day'), 'days')}
                      일 후 무료
                    </div>
                    <div className='flex items-center ml-1'>
                      {!viewedChapterData?.includes(chapter._id) ? (
                        <>
                          <img
                            src='/images/toon/toonCoin.png'
                            alt='toonCoin'
                            className='w-[4vw] h-[4vw] ml-1'
                          />
                          <div className='ml-1'>{toonPrice}C</div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                {!chapter.isFree &&
                  viewedChapterData?.some(
                    (item) =>
                      item.chapterId.toString() === chapter._id &&
                      moment()
                        .startOf('day')
                        .diff(
                          moment(item.firstViewedAt).startOf('day'),
                          'days',
                        ) > rentPeriod,
                  ) && (
                    <div className='absolute mt-[18vw] right-[6vw] p-2 text-xs text-gray-600 rounded-tr rounded-bl'>
                      열람 기간만료
                    </div>
                  )}
                {!chapter.isFree &&
                  viewedChapterData &&
                  !viewedChapterData?.some(
                    (item) =>
                      item.chapterId.toString() === chapter._id &&
                      moment()
                        .startOf('day')
                        .diff(
                          moment(item.firstViewedAt).startOf('day'),
                          'days',
                        ) > rentPeriod,
                  ) && (
                    <div className='absolute mt-[18vw] right-[6vw] p-2 text-[2.4vw] text-gray-600'>
                      {(() => {
                        const viewedChapter = viewedChapterData?.find(
                          (item) => item.chapterId.toString() === chapter._id,
                        )
                        if (viewedChapter && viewedChapter.firstViewedAt) {
                          const dateStr = moment(viewedChapter.firstViewedAt)
                            .startOf('D')
                            .add(3, 'days')
                            .format('M월 D일')
                          return `${dateStr}까지 무료열람`
                        }
                        return null // 아무 것도 반환하지 않을 때
                      })()}
                    </div>
                  )}
              </button>
            ))}

        {/* 공개일 이후인 회차 표시 */}
        {chapterInfo
          ?.filter((chapter) => moment().isAfter(chapter?.publishedAt))
          .map((chapter) => (
            <button
              type='button'
              className={`relative my-2 flex flex-row rounded-lg border-b border-[#f5f5f5] ${
                viewedChapterData?.some(
                  (item) =>
                    item.chapterId.toString() === chapter._id &&
                    !item.isBulkPurchased,
                )
                  ? 'bg-gray-100'
                  : ''
              }`}
              key={chapter.chapterTitle || chapter?.episode}
              onClick={() => {
                if (chapter.isFree === false) {
                  const viewedChapter = viewedChapterData?.find(
                    (item) => item.chapterId.toString() === chapter._id,
                  )
                  // waitForFreeAvailable이 true이고, waitForFreeInfo.eventStartedAt이 현재 시간보다 이전이고, waitForFreeInfo.eventEndedAt이 현재 시간보다 이후이며,
                  // 해당 episode가 전체 길이 - waitForFreeInfo.recentChapterLimit 보다 작을때
                  // waitForFreeAvailable를 False로 바꾸면서 사용 시점을 로깅하면서 열람 가능하게 함
                  const waitForFreeChapter =
                    waitForFreePossible &&
                    chapterInfo?.filter((chapter) =>
                      moment().isAfter(chapter?.publishedAt),
                    )?.length -
                      AltoonUserStore?.currentViewLog?.waitForFreeInfo
                        ?.recentChapterLimit >=
                      chapter.episode

                  if (!viewedChapter) {
                    if (waitForFreeChapter) {
                      setModalType('useWaitForFree')
                      setModalProp({
                        chapter,
                        toonInfo,
                        viewLog: AltoonUserStore?.currentViewLog,
                        isWaitForFreeLimitedChapter:
                          chapterInfo?.filter((chapter) =>
                            moment().isAfter(chapter?.publishedAt),
                          )?.length -
                            AltoonUserStore?.currentViewLog?.waitForFreeInfo
                              ?.recentChapterLimit <
                          chapter.episode,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else if (
                      AltoonUserStore?.userData?.toonCoin >= toonPrice
                    ) {
                      setModalType('useCoin')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      setModalType('lackCoin')
                      setModalProp({ toonInfo })
                      setOpenTicketModal(true)
                    }
                  } else {
                    const daysSinceFirstViewed = moment()
                      .startOf('day')
                      .diff(
                        moment(viewedChapter.firstViewedAt).startOf('day'),
                        'days',
                      )
                    if (daysSinceFirstViewed > rentPeriod) {
                      if (waitForFreeChapter) {
                        setModalType('useWaitForFree')
                        setModalProp({
                          chapter,
                          toonInfo,
                          viewLog: AltoonUserStore?.currentViewLog,
                          isWaitForFreeLimitedChapter:
                            chapterInfo?.filter((chapter) =>
                              moment().isAfter(chapter?.publishedAt),
                            )?.length -
                              AltoonUserStore?.currentViewLog?.waitForFreeInfo
                                ?.recentChapterLimit <
                            chapter.episode,
                          episode: chapter?.episode,
                          rentPeriod,
                        })
                        setOpenTicketModal(true)
                      } else {
                        setModalType('rentExpired')
                        setModalProp({
                          chapter,
                          toonInfo,
                          episode: chapter?.episode,
                          rentPeriod,
                        })
                        setOpenTicketModal(true)
                      }
                    } else {
                      navigateToReaderPage({
                        chapter,
                        episode: chapter?.episode,
                        component: 'detailPage_chapter',
                      })
                    }
                  }
                } else {
                  navigateToReaderPage({
                    chapter,
                    episode: chapter?.episode,
                    component: 'detailPage_chapter',
                  })
                }
              }}
            >
              <div className='relative'>
                <AlwayzImage
                  src={resize(
                    chapter.detailThumbnail,
                    toonInfo?.verticalThumbnail ? 360 : 234,
                    toonInfo?.verticalThumbnail ? 486 : 196,
                    'fill',
                  )}
                  alt={chapter.chapterTitle}
                  className={
                    toonInfo?.verticalThumbnail
                      ? 'w-[30vw] h-[43vw] rounded-lg resize-y self-stretch'
                      : 'w-[30vw] h-[25vw] rounded-lg resize-y self-stretch'
                  }
                  lazy
                />

                {moment().isAfter(
                  moment(
                    AltoonUserStore?.currentViewLog?.waitForFreeInfo
                      ?.eventStartedAt,
                  ),
                ) &&
                  moment().isBefore(
                    moment(
                      AltoonUserStore?.currentViewLog?.waitForFreeInfo
                        ?.eventEndedAt,
                    ),
                  ) &&
                  chapterInfo?.filter((chapter) =>
                    moment().isAfter(chapter?.publishedAt),
                  )?.length -
                    AltoonUserStore?.currentViewLog?.waitForFreeInfo
                      ?.recentChapterLimit >=
                    chapter?.episode && (
                    <img
                      src='/images/toon/waitForFreeIcon.png'
                      alt='ticket'
                      className='absolute bottom-[1vw] right-[1vw] w-[13vw] h-auto'
                    />
                  )}

                {chapter?.onlyPaidCoin && (
                  <img
                    src='/images/toon/onlyPaidCoinIcon.png'
                    alt='ticket'
                    className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                  />
                )}
                {moment().format('YYYY-MM-DD') ===
                  moment(chapter?.publishedAt).format('YYYY-MM-DD') && (
                  <img
                    src='/images/toon/upIcon.png'
                    alt='ticket'
                    className='absolute top-[1vw] left-[1vw] w-[5vw] h-[3vw]'
                  />
                )}
              </div>
              <div className='p-4 w-[60vw] h-[12vh] flex flex-col items-start justify-center font-semibold text-[3.8vw]'>
                <div
                  className={`text-start ${
                    viewedChapterData?.some(
                      (item) => item.chapterId.toString() === chapter._id,
                    )
                      ? 'text-gray-400'
                      : ''
                  }`}
                >
                  {chapter.chapterTitle}
                </div>
                <div
                  className={`pt-1 flex flex-row items-center text-[3vw] font-medium ${
                    viewedChapterData?.some(
                      (item) => item.chapterId.toString() === chapter._id,
                    )
                      ? 'text-gray-400'
                      : 'text-gray-600'
                  }`}
                >
                  {chapter.chapterRatingCount !== 0 && (
                    <div className='flex flex-row items-center justify-end mr-2'>
                      <img
                        alt=''
                        style={{
                          marginRight: 2,
                          width: '2.4vw',
                          height: '2.4vw',
                        }}
                        src='/images/toon/star.png'
                      />
                      {Math.round(
                        (chapter?.chapterRatingScore /
                          chapter?.chapterRatingCount) *
                          100,
                      ) / 100 || 0}
                    </div>
                  )}
                  <div className=''>
                    {moment(chapter.publishedAt).format('YY.MM.DD')}
                  </div>
                  <div className='flex items-center ml-1'>
                    {chapter.isFree === false &&
                    // moment().isAfter(
                    //   moment(chapter.publishedAt).add(previewDays, 'days'),
                    // ) &&
                    !viewedChapterData?.includes(chapter._id) ? (
                      <>
                        <img
                          src='/images/toon/toonCoin.png'
                          alt='toonCoin'
                          className='w-[4vw] h-[4vw] ml-1'
                        />
                        <div className='ml-1'>{toonPrice}C</div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              {!chapter.isFree &&
                viewedChapterData?.some(
                  (item) =>
                    item.chapterId.toString() === chapter._id &&
                    moment()
                      .startOf('day')
                      .diff(moment(item.firstViewedAt).startOf('day'), 'days') >
                      rentPeriod,
                ) && (
                  <div className='absolute mt-[18vw] right-[6vw] p-2 text-xs text-gray-600 rounded-tr rounded-bl'>
                    열람 기간만료
                  </div>
                )}
              {!chapter.isFree &&
                viewedChapterData &&
                !viewedChapterData?.some(
                  (item) =>
                    item.chapterId.toString() === chapter._id &&
                    moment()
                      .startOf('day')
                      .diff(moment(item.firstViewedAt).startOf('day'), 'days') >
                      rentPeriod,
                ) && (
                  <div className='absolute mt-[18vw] right-[6vw] p-2 text-[2.4vw] text-gray-600'>
                    {(() => {
                      const viewedChapter = viewedChapterData?.find(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                      if (viewedChapter && viewedChapter.firstViewedAt) {
                        const dateStr = moment(viewedChapter.firstViewedAt)
                          .startOf('D')
                          .add(3, 'days')
                          .format('M월 D일')
                        return `${dateStr}까지 무료열람`
                      }
                      return null // 아무 것도 반환하지 않을 때
                    })()}
                  </div>
                )}
            </button>
          ))}
      </div>
    )
  }

  const ScrollButton = () => {
    return (
      <div type='button' className='w-[8vw] fixed bottom-[10vh] right-4'>
        <button
          type='button'
          className='rounded-full bg-white shadow-md'
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop = 0
            }
          }}
        >
          <img src='/images/toon/toTopIcon.png' alt='up' className='w-full' />
        </button>
        <button
          type='button'
          className='rounded-full bg-white shadow-md'
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight
            }
          }}
        >
          <img
            src='/images/toon/toBottomIcon.png'
            alt='up'
            className='w-full'
          />
        </button>
      </div>
    )
  }

  const WaitForFreeIntroComponent = ({ number, mainText, subText }) => {
    return (
      <div>
        <div className='flexRow justify-start w-full mb-[4vw] text-[3.6vw] whitespace-nowrap'>
          <img
            src={`/images/toon/waitForFreeDescription${number}.png`}
            alt='waitForFreeIcon'
            className='w-[14vw] h-[14vw] mr-[4vw]'
          />
          <div className='flexCol items-start'>
            <div className='text-[3.8vw] text-[#71737C] font-semibold'>
              {subText}
            </div>
            <div className='text-[4.5vw] text-[#464953] font-bold'>
              {mainText}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        className='w-full overflow-y-scroll pt-[8vh]'
        style={{ height: '100vh' }}
        ref={scrollContainerRef}
      >
        <HeaderComponent />
        {chapterInfo?.length > 0 && (
          <div className='px-4'>
            <ToonInfoSection />
            {
              <RelatedToonsTabSection onTabChange={handleTabChange} />
              //  (
              //   <div className='h-[4vw]' />
              // )
            }
            {/* {JSON.stringify(relatedToonAB)} */}
            {selectedTab === 'allChapters' && (
              <>
                <ChapterCountingSection />

                <GoodsCountComponent />
                {/* {toonInfo?.weekday && <SeolNoticeComponent />} */}
                {toonInfo?.notice?.length > 0 && <NoticeComponent />}
                <WeeklyNudge />
                <ChapterList />
              </>
            )}
            {selectedTab === 'similar' && (
              <>
                <RecommendRelatedToonsComponent
                  toonInfo={toonInfo}
                  type='relatedToonsDetail'
                />
              </>
            )}
          </div>
        )}
        <ScrollButton />
        {isLoading && <LoadingIndicator />}
        {selectedTab === 'allChapters' && <FirstChapterButton />}
        {showBulkPurchaseButton &&
          !bulkPurchaseComplete &&
          selectedTab === 'allChapters' && <BulkPurchaseButton />}
        {openTicketModal && (
          <ToonModal
            modalName={modalType}
            toonId={toonId}
            coinCount={
              AltoonUserStore?.userData?.toonCoin > 0
                ? AltoonUserStore?.userData?.toonCoin || 0
                : 0
            }
            modalProp={modalProp}
            setOpenTicketModal={setOpenTicketModal}
          />
        )}
        <BottomSheet
          className='w-full border-none outline-none bottom-sheet relative z-20'
          onDismiss={() => {
            setOpenBottomSheet(false)
          }}
          scrollLocking={false}
          header={false}
          open={openBottomSheet}
          // snapPoints={({ maxHeight }) => [maxHeight, maxHeight * 0.8]}
        >
          <div className='p-[6vw]'>
            <div
              className='pb-4 text-[6vw] font-bold'
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'keep-all',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {AltoonUserStore?.currentViewLog?.waitForFreeInfo?.waitingTime ||
                3}
              시간마다 1장 무료
            </div>

            <WaitForFreeIntroComponent
              number={1}
              mainText={`${
                AltoonUserStore?.currentViewLog?.waitForFreeInfo?.waitingTime ||
                3
              }시간 기다리면 다시 이용권 1장 받아요`}
              subText={`'기다리면 무료' 이용권을 사용한 후`}
            />
            <WaitForFreeIntroComponent
              number={2}
              mainText='기다무 이용권으로 감상할 수 없어요'
              subText={`최신 ${
                AltoonUserStore?.currentViewLog?.waitForFreeInfo
                  ?.recentChapterLimit || 10
              }회차는`}
            />
            <WaitForFreeIntroComponent
              number={3}
              mainText='작품을 3일동안 감상할 수 있어요'
              subText={`'기다리면 무료' 이용권을 사용하면`}
            />
            <div className='w-[60vw] px-[6vw] py-[4vw] ml-[14vw] my-[4vw] flexCol bg-[#F6F6FA] text-[4vw] rounded-lg'>
              <div className='w-full flexRow items-center justify-between'>
                <div className='font-semibold'>나의 기다리면 무료</div>
                <div className='font-bold'>
                  {AltoonUserStore?.currentViewLog?.waitForFreeAvailable
                    ? 1
                    : 0}
                  회
                </div>
              </div>
              {!AltoonUserStore?.currentViewLog?.waitForFreeAvailable && (
                <div className='mt-[2vw] text-[3vw] text-[#71737C] font-bold'>
                  {moment(
                    AltoonUserStore?.currentViewLog?.lastWaitForFreeUsedAt,
                  )
                    .add(
                      AltoonUserStore?.currentViewLog?.waitForFreeInfo
                        ?.waitingTime || 3,
                      'hours',
                    )
                    .format('HH:mm')}
                  에 다시 생겨요
                </div>
              )}
            </div>
            <TreasureHuntButton
              clickable
              text='확인'
              className='py-4 mt-6 text-[5vw]'
              onClick={() => {
                setOpenBottomSheet(false)
              }}
            />
          </div>
        </BottomSheet>
      </div>
    </AppScreen>
  )
})

export default AltoonDetailPage
