import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'

import MainToonComponent from '../../components/toon/mainToonComponent'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const ThemeSaleEventPage = observer(({ eventInfo, toonsInfo }) => {
  const [selectedTab, setSelectedTab] = useState(
    eventInfo?.tabInfo?.tabTitle?.[0] || '인기',
  )

  const filteredToons = useMemo(() => {
    if (!eventInfo?.isMultiTab) return toonsInfo

    const currentTabIndex = eventInfo.tabInfo.tabTitle.indexOf(selectedTab)
    console.log('currentTabIndex', currentTabIndex)

    const tabToonIds = eventInfo.tabInfo.tabToonIdList[currentTabIndex].map(
      (id) => id,
    )
    console.log('tabToonIds', tabToonIds)

    return toonsInfo?.filter((toon) => tabToonIds.includes(toon._id))
  }, [toonsInfo, selectedTab, eventInfo])

  // 탭이 1개이거나 없는 경우
  if (!eventInfo?.isMultiTab) {
    return (
      <div className='bg-white px-[2vw] -mt-[2vw]'>
        <div className={`grid grid-cols-${eventInfo?.columnNumber || 3}`}>
          {toonsInfo?.map((toon, index) => (
            <MainToonComponent
              key={toon._id}
              toon={toon}
              index={index}
              type='themeSale'
              component='AltoonEventPage_themeSale'
            />
          ))}
        </div>
      </div>
    )
  }

  // 탭이 여러 개인 경우
  return (
    <div className='bg-white -mt-[2vw]'>
      {/* 탭 리스트 */}
      <div className='flex'>
        {eventInfo.tabInfo.tabTitle.map((tab) => (
          <button
            type='button'
            key={tab}
            className={`flex-1 py-[3vw] text-[4vw] font-bold rounded-t-xl ${
              selectedTab === tab
                ? 'text-white bg-[#4099FF]'
                : 'text-[#4099FF] bg-white'
            }`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div
        className={`px-[2vw] py-[6vw] grid grid-cols-${
          eventInfo?.columnNumber || 3
        } gap-[2vw] bg-[#4099FF]`}
      >
        {filteredToons?.map((toon, index) => (
          <MainToonComponent
            key={toon._id}
            toon={toon}
            index={index}
            type='themeSale'
            component='AltoonEventPage_themeSale'
          />
        ))}
      </div>
    </div>
  )
})

export default ThemeSaleEventPage
