// @ts-nocheck
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import fetchWrapper from './fetchWrapper'
import { CommentSortType, LoadNovelChapterCommentList } from './interface'

const API_URI = process.env.REACT_APP_API_URI

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource =
    method === 'GET' ? `${url}?${new URLSearchParams(params)}` : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init.method = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)
    const data = await res.json()
    return data
  } catch (err) {
    return null
  }
}

class BackendApis {
  async getExpLog(logNum) {
    try {
      const options = {
        method: 'GET',
        cache: 'no-cache',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/level/exp/log?logNum=${logNum}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadUserExpInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(`${API_URI}/users/level/exp`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkTreasureBoxOpened() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/level/tbox/check`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logDailyCheckInScreen(type) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCheckInPoint() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in-for-web`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getExpiringPoint() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/expiring-points`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMonthlyPointHistory() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/monthly-point-history`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSixMonthPointHistory() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/six-month-point-history`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logExpirePointNotification() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/log-expire-point-notification`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkInTeam(userId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-team?userId=${userId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async searchTeamName(searchTerm) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/search-check-in-team?searchTerm=${searchTerm}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async createTeamCheckIn(teamName) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ teamName }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/create-check-in-team`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getHowManyTimesTodayHiddenPointNudge() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/hidden-point-nudge-howmany-times-today`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async couponPackData() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/coupon-pack-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async receiveCheckInCouponFromPack(
    value,
    couponType,
    condition,
    conditionType,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ value, couponType, condition, conditionType }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/receive-check-in-coupon-from-pack`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async drawCouponPack() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/draw-check-in-coupon-pack`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logNewUserBenefitLog(code, alwayzInvitorId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code, alwayzInvitorId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/log-new-user-benefit-log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async joinTeamCheckIn(teamId, newUser = false) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ teamId, newUser }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/join-check-in-team`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async leaveTeamCheckIn() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/leave-check-in-team`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async pokeTeamCheckIn(teamId, teammateId, teammateName) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ teamId, teammateId, teammateName }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/poke-check-in-team`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async teamCheckInName() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-team-name`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRaffleHistory() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-raffle-history`,
        options,
      )
      console.log(res, 'res')
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getProceedingRaffle() {
    try {
      const options = {
        method: 'GET',
      }

      const res = await fetchWrapper(
        `${API_URI}/users/check-in-proceeding-raffle`,
        options,
      )
      console.log(res, 'res')
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getPreviousRaffleWinner() {
    try {
      const options = {
        method: 'GET',
      }

      const res = await fetchWrapper(
        `${API_URI}/users/check-in-previous-raffle-winner`,
        options,
      )
      console.log(res, 'res')
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logAnnouncementViewed(itemId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/log-announcement-viewed`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logEnteringRaffle(itemId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/log-entering-raffle`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOfferwallListBuzzvil(params) {
    try {
      const options = {
        method: 'GET',
      }
      const queryString = new URLSearchParams(params).toString()
      const res = await fetchWrapper(
        `${API_URI}/buzzvil/offerwall-list?${queryString}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOfferwallAdBuzzvil(params) {
    try {
      const options = {
        method: 'GET',
      }
      const queryString = new URLSearchParams(params).toString()

      const res = await fetchWrapper(
        `${API_URI}/buzzvil/offerwall?${queryString}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOfferwallMetaBuzzvil(params) {
    try {
      const options = {
        method: 'GET',
      }
      const queryString = new URLSearchParams(params).toString()

      const res = await fetchWrapper(
        `${API_URI}/buzzvil/offerwall-meta?${queryString}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  recordOfferwallEventLog(params) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      }
      fetchWrapper(`${API_URI}/buzzvil/offerwall-event-log`, options)
    } catch (err) {
      console.log(err)
    }
  }

  async getUserCodePushVersion() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/code-push-version`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUserOrderData({ days }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/orders/user-order-data?days=${days}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGifticonItems(skip, type) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/items/gifticon-items?skip=${skip}&type=${type}`,
        options,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async sendDailyCheckInGifticonItems(gifticonData) {
    try {
      const { itemType, requestId } = gifticonData

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemType: itemType,
          source: 'dailyCheckIn',
          requestId: requestId,
        }),
      }

      const res = await fetchWrapper(`${API_URI}/gifticon/create`, options)

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGifticonItemById(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/items/gifticon-items-by-id?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSpeedGifticonStock(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/items/speed-gifticon-stock?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSpeedGifticonItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/items/speed-gifticon-items`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCashbackHistory(loadedLogsNum) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/cashback-history?loadedLogsNum=${loadedLogsNum}`,
        options,
      )
      console.log(res, 'res')
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCheckInCouponHistory(loadedLogsNum) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-coupon-history?loadedLogsNum=${loadedLogsNum}`,
        options,
      )
      console.log(res, 'res')
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGifticonExchangeHistory(loadedLogsNum) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-gifticon-history?loadedLogsNum=${loadedLogsNum}`,
        options,
      )
      console.log(res, 'res')
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async startRoulette(rouletteType, rouletteTitle, prizes) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rouletteType, rouletteTitle, prizes }),
      }
      const res = await fetchWrapper(`${API_URI}/users/start-roulette`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async giveRouletteReward(rouletteType, rouletteTitle, prizes) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rouletteType, rouletteTitle, prizes }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/give-roulette-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async fetchRouletteTicket() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/fetch-roulette-ticket`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async giveRouletteTicket(
    rouletteType,
    rouletteTitle,
    missionTitle,
    ticketCount,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          rouletteType,
          rouletteTitle,
          missionTitle,
          ticketCount,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/give-roulette-ticket`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async giveRouletteTicketWithUserId(
    invitorId,
    rouletteType,
    rouletteTitle,
    missionTitle,
    ticketCount,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          invitorId,
          rouletteType,
          rouletteTitle,
          missionTitle,
          ticketCount,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/give-roulette-ticket-with-userid`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logRewardTime(missionType) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          missionType,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/log-mission-reward-time`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async receiveCheckInPurchaseReward(rewardData) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rewardData }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/receive-check-in-purchase-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getConsecutiveCheckIn() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/consecutive-check-in`,
        options,
      )
      console.log(res, 'res')
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logConsecutiveCheckIn() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/log-consecutive-check-in`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async resetConsecutiveCheckInLog(streakStopped) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ streakStopped }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/reset-consecutive-check-in`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logCheckInPigPet() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/log-check-in-pig-pet`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async usePigItem(itemType, itemQuantity) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemType, itemQuantity }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/use-check-in-pig-item`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async evaporateHappiness() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/evaporate-check-in-pig-happiness`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setBoostTimeCheckInMission(count) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ count }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/boost-time-check-in-mission`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async pigData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-pig-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async pigMissionData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-pig-mission-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async pigLevelData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-pig-level-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async pigCustomizationData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-pig-customization-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async purchaseReward() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/purchase-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getLuckyScratchPurchaseReward() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/lucky-scratch-purchase-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadDondonSpeechBubble(eventType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-pig-speech-bubble?eventType=${eventType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async luckyScratchLog() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-pig-lucky-scratch-log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async retrieveBonusFood() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/retrieve-pig-bonus-food`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async resetBonusFood() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/reset-pig-bonus-food`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async gainFood(missionType, rewardAmount, rewardType) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ missionType, rewardAmount, rewardType }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/gained-pig-food`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async feedCheckInPig(multiple) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          multiple,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/feed-check-in-pig`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async openPigEnvelope() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/open-check-in-pig-envelope`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordReceivedCoupon(type, couponIndex) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, couponIndex }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/coupons`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordReceivedGifticon(rewardInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rewardInfo }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/gifticons`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGifticonDrawHistory(loadedLogsNum) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-draw-history?loadedLogsNum=${loadedLogsNum}`,
        options,
      )
      console.log(res, 'res')
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async drawGifticon(point, targetGifticon) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          point,
          targetGifticon,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/draw`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async drawedGifticonReceived(drawId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          drawId,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/draw-to-received`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async gifticonLevel() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/gifticon-level-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async upgradeGifticon(currentDrawData, selectedMaterialDrawInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentDrawData,
          selectedMaterialDrawInfo,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/upgrade-gifticon`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCurrentDate() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/now`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTodayRewards() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in-history`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDailyCheckInLevelData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in-level`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCheckInGuestInfo() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-invite/guest-user`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTodayCheckInNewInvitationHistory() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/get-today-new-invitation-history`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUserCheckInPushNotificationSetting() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-push-status`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDailyCheckInQuizData(isTutorial, solvedQuizList) {
    try {
      const options = {
        method: 'GET',
      }
      let tempSolvedQuizList = solvedQuizList.map((item) => item.quizName)
      tempSolvedQuizList = JSON.stringify(tempSolvedQuizList)
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in-quiz?isTutorial=${isTutorial}&solvedQuizList=${tempSolvedQuizList}`,
        options,
      )
      const data = await res.json()

      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getIsCheckInInviteNewUser() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-invite-new-user`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadDailyCheckInQuickLinkNotificationCount() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/get-daily-check-in-notificaiton-count`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async processOpenEnvelopeV2(
    type,
    consecutiveBonus,
    rewardType,
    reducedReward,
    alwayzInvitorId,
    isNewUserInvitation,
    isCodepushed,
    isRewardReducedToHalf = false,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
          consecutiveBonus,
          rewardType,
          reducedReward,
          alwayzInvitorId,
          isNewUserInvitation,
          isCodepushed,
          isRewardReducedToHalf,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in-history/process-open-envelope/v2`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setAlfarmEventReward() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/set-alfarm-event-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAlfarmTwoDaysLogs() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/get-alfarm-last-2days-logs`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkDailyCheckInQuizAnswer(type, quizName, userAnswer) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
          quizName,
          userAnswer,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in-quiz/check-quiz-answer`,
        options,
      )
      const data = await res.json()

      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logAlarmMission() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/log-alarm-mission`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getInintModalData(params = {}) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/init-modal`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkInSet(params = {}) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/set`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUserDealStatus() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(`${API_URI}/users/deal/status`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateUserDealStatus(dealType, hours = 2, minutes = 0) {
    console.log(dealType, hours, minutes)
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dealType,
          hours,
          minutes,
        }),
      }
      const res = await fetchWrapper(`${API_URI}/users/deal/status`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setDailyCheckInPushApprovedLog(isApproved) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          isApproved,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/set-tomorrow-push-log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 출석체크 친구 초대 새버전 친구 정보 불러오기
  async getCheckInInviteFriendsNewInfo() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in-invite-new/friends-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 출석체크 친구 초대 새버전 보상 받음 처리
  async setTodayCheckInNewInvitationHistory(guestId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ guestId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/set-today-new-invitation-history`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logGeoLocation(longitude, latitude) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ longitude, latitude }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/log-geo-location`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkIfMatchedToday(friendId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ friendId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/check-if-matched-today`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getNearByGeoData(longitude, latitude) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ longitude, latitude }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/get-geo-location`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAnyGeoData() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/get-any-geo-location`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSpecificGeoData(invitorId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ invitorId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/get-specific-geo-location`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async moneyTreeInviteLogging(matchData, isInvitor, isNewUser) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ matchData, isInvitor, isNewUser }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/money-tree-invite-logging`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMoneyTreeInviteLog() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/money-tree-invite-log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMoneyTreeInviteLogWithId(userId) {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/money-tree-invite-log-with-id?userId=${userId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async todayMatchData() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${API_URI}/users/money-tree-today-match`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logMoneyTreeMatch(matchData, matchType, multiple) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ matchData, matchType, multiple }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/log-money-tree-match`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getPigCloseReward() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/pig-close-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async putPigCloseReward() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/daily-check-in/pig-close-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async moneyTreeMatchReward(matchInfoId, isFriend) {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${API_URI}/users/money-tree-match-reward?matchInfoId=${matchInfoId}&isFriend=${isFriend}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadAuthInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(`${API_URI}/toons/load-auth-info`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadAllNovels() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-all-novels`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadNovelInfoById({ novelId }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-novel-info?novelId=${novelId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadNovelDetail({ novelId }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-novel-detail?novelId=${novelId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadNovelViewLog({ novelId }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-novel-view-log?novelId=${novelId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadNovelChapterInfo({ novelId, chapterId }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/novel/chapter/info?novelId=${novelId}&chapterId=${chapterId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadNovelChapterDetail({ novelId, chapterId }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/novel/chapter/detail?novelId=${novelId}&chapterId=${chapterId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logNovelChapterView({ novelId, chapterId }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ novelId, chapterId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/novel/chapter/log-view`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadNovelChapterCommentList(params: {
    novelId: string
    chapterId: string
    sortType: CommentSortType
    count: number
    skip: number
  }) {
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const res = await fetchWrapper(
      `${API_URI}/toons/novel/${params.novelId}/chapter/${params.chapterId}/comment?sortType=${params.sortType}&count=${params.count}&skip=${params.skip}`,
      options,
    )
    const data = await res.json()
    return data as LoadNovelChapterCommentList
  }

  async postNovelChapterComment(params: {
    comment: string
    novelId: string
    chapterId: string
  }) {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        comment: params.comment,
        type: 'novel',
      }),
    }
    const res = await fetchWrapper(
      `${API_URI}/toons/novel/${params.novelId}/chapter/${params.chapterId}/comment`,
      options,
    )
    const data = await res.json()
    return data
  }

  async deleteNovelChapterComment(params: {
    novelId: string
    chapterId: string
    commentId: string
  }) {
    const options = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
    const res = await fetchWrapper(
      `${API_URI}/toons/novel/${params.novelId}/chapter/${params.chapterId}/comment/${params.commentId}`,
      options,
    )
    const data = await res.json()
    return data
  }

  async likeNovelChapterComment(params: {
    novelId: string
    chapterId: string
    commentId: string
    setLiked: boolean
  }) {
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        setLiked: params.setLiked,
      }),
    }
    const res = await fetchWrapper(
      `${API_URI}/toons/novel/${params.novelId}/chapter/${params.chapterId}/comment/${params.commentId}/like`,
      options,
    )
    const data = await res.json()
    return data
  }

  async reportNovelChapterComment(params: {
    novelId: string
    chapterId: string
    commentId: string
    type: 'block' | 'report'
  }) {
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        type: params.type,
      }),
    }
    const res = await fetchWrapper(
      `${API_URI}/toons/novel/${params.novelId}/chapter/${params.chapterId}/comment/${params.commentId}/action`,
      options,
    )
    const data = await res.json()
    return data
  }

  async likeNovelChapter(params: {
    novelId: string
    chapterId: string
    setLiked: boolean
  }) {
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        setLiked: params.setLiked,
      }),
    }
    const res = await fetchWrapper(
      `${API_URI}/toons/novel/${params.novelId}/chapter/${params.chapterId}/like`,
      options,
    )
    const data = await res.json()
    return data
  }

  async loadAllToons() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(`${API_URI}/toons/load-all-toons`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadTrendingToons() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-trending-toons`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadRecommendingToons(selectedGenres, recommendType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-recommending-toons?selectedGenres=${selectedGenres}&recommendType=${recommendType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadRecentlyViewedToons() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-recently-viewed-toons`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadLikedToonsAndNovels() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-liked-toons`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadToonDetail(toonId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-toon-detail?toonId=${toonId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadToonChapterInfo(toonId, ascendingSort) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-toon-chapters?toonId=${toonId}&ascendingSort=${ascendingSort}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadChapter(chapterId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-chapter?chapterId=${chapterId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadChapterByEpisode(toonId, episode) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-chapter-episode?toonId=${toonId}&episode=${episode}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadComments(chapterId, sortType, count, skip) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-comments?chapterId=${chapterId}&sortType=${sortType}&count=${count}&skip=${skip}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadCommentLikedData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-comment-liked-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadBannerData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-banner-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadToonPopUpModal() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-modal-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logModalReject() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/set-modal-reject`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setToonInitModal({ initModalList }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ initModalList }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/set-toon-init-modal`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logToonError(screen, api, result) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ screen, api, result }),
      }
      const res = await fetchWrapper(`${API_URI}/toons/log-toon-error`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logComment(toonId, chapterId, comment) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ toonId, chapterId, comment }),
      }
      const res = await fetchWrapper(`${API_URI}/toons/log-comment`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logCommentLike(commentId, setLiked) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ commentId, setLiked }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/log-comment-like`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async modifyToonComment(type, commentId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, commentId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/modify-toon-comment`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadUserData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(`${API_URI}/toons/load-user-data`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async removeFromViewLog({ type, id }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, id }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/remove-from-viewed-toon`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadToonViewLog(toonId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-view-log?toonId=${toonId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadToonRating(toonId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-rating?toonId=${toonId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadToonMission() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-toon-mission`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadConnectingItemInfo(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-connecting-item?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadTicketItemInfo(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-ticket-item?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadToonEventInfo(eventId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-toon-event?eventId=${eventId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadToonInfoByIds(toonIdList) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-toon-info-by-ids?toonIdList=${toonIdList}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadNovelInfoByIds(novelIdList) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-novel-info-by-ids?novelIdList=${novelIdList}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadCoinHistoryData(type, skip, limit) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-coin-history-data?type=${type}&skip=${skip}&limit=${limit}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateToonInviteLog({ alwayzInvitorId }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ alwayzInvitorId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/update-invite-log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadMissionData(eventId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-mission-event?eventId=${eventId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateMissionData({ eventId, missionType, missionReward }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ eventId, missionType, missionReward }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/update-mission-event`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async openMissionReward({ eventId }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ eventId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/open-mission-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setMissionInitModal({ initModalList }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ initModalList }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/set-mission-event-init-modal`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadLeaderboardInfo(eventId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-leaderboard?eventId=${eventId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateLeaderboardInfo(eventId, score) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ eventId, score }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/update-leaderboard`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadPollData(eventId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/load-poll-data?eventId=${eventId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async submitPoll(eventId, toonId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ eventId, toonId }),
      }
      const res = await fetchWrapper(`${API_URI}/toons/submit-poll`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async participateLeaderboard(eventId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ eventId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/participate-leaderboard`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setTestingGroup(testingGroup) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ testingGroup }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/set-testing-group`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async giveAltoonMissionReward(missionType) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ missionType }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/mission/giveReward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logToonAction(screen, action, description) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ screen, action, description }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/log-toon-action`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logToonActionForAlfarmMission(toonId, chapterId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ toonId, chapterId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/log-toon-action-for-alfarm`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async chapterBulkPurchase(toonId, rentPeriod) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ toonId, rentPeriod }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/log-chapter-bulk-purchase`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logChapterViewed(toonId, chapterId, rentPeriod) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ toonId, chapterId, rentPeriod }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/log-chapter-viewed`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logToonChapterLike(toonId, chapterId, setLiked) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ toonId, chapterId, setLiked }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/log-chapter-liked`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logToonChapterRating(toonId, chapterId, ratingScore, type) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ toonId, chapterId, ratingScore, type }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/log-chapter-rating`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setToonAlarm(type, toonId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, toonId }),
      }
      const res = await fetchWrapper(`${API_URI}/toons/set-toon-alarm`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async useToonCoin(toonId, chapterId, quantity) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ toonId, chapterId, quantity }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/use-toon-ticket`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async useWaitForFree(toonId, contentType) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ toonId, contentType }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/use-wait-for-free`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async userInfoByUserId() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/user-info-by-id`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async requestWebBillingPay(
    payType,
    orderType,
    orderInfo,
    paymentMethodId,
    bid,
    paymentStartTime,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          payType,
          orderType,
          orderInfo,
          paymentMethodId,
          bid,
          paymentStartTime,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/orders/web-pay-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerWebPaymentErrorLog(log) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          log,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/web-pay-error-log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async sendAltoonRequest({ requestType, message }) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          requestType,
          message,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/send-altoon-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logToonTicketOrder(totalPrice, itemInfo, userRepresentativeCardInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          totalPrice,
          itemInfo,
          userRepresentativeCardInfo,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/orders/log-toon-ticket-order`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async rewardToonPurchase(itemId, orderId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemId, orderId }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/reward-toon-purchase`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async exchangeToonCoin(type, rewardAmount, cost) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, rewardAmount, cost }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/exchange-toon-coin`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setWebUserInfo({ webUserId, shareType }) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ webUserId, shareType }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/set-web-user-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logScreenNavigation({ type, from, to, params }) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, from, to, params }),
      }
      const res = await fetchWrapper(
        `${API_URI}/toons/log-screen-navigation`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordEventLog(eventLocation, eventAction) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventLocation,
          eventAction,
        }),
      }
      const res = await fetchWrapper(`${API_URI}/users/event-log`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordScreenLog(userUsageId, screen) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userUsageId,
          screen,
        }),
      }
      const res = await fetchWrapper(`${API_URI}/users/usages`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordDealLog(sessionId, eventType, action, stage, modalType) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sessionId,
          eventType,
          action,
          stage,
          modalType,
        }),
      }
      const res = await fetchWrapper(`${API_URI}/users/deal-log`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordUserCheckInNotificationSetting(
    checkInNotificationSetting,
    checkInNotificationTime,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          checkInNotificationSetting,
          checkInNotificationTime,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/check-in-push-settings`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getEventPageMapping(eventPageName) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${API_URI}/event-page-mapping?eventPageName=${eventPageName}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRecommendSkusFromRecServer(c1, c2, enteringComponent) {
    try {
      const options = {
        method: 'GET',
      }
      const url = `${API_URI}/items/recommend-skus/lgbm?c1=${
        typeof c1 === 'number' ? c1 : -1
      }&c2=${
        typeof c2 === 'number' ? c2 : -1
      }&enteringComponent=${enteringComponent}`
      const res = await fetchWrapper(url, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemsInfoByItemIndices(
    itemIndices,
    enteringComponent = '',
    query = '',
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemIndices,
          enteringComponent,
          query,
          codePushVersion: AuthStore.codePushVersion,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/items/info-requests/item-indices`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealItemsV3(
    dealType,
    loadNum,
    displayVersion,
    itemIndices = [],
    condition,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dealType,
          loadNum,
          displayVersion,
          itemIndices,
          condition,
        }),
      }
      const res = await fetchWrapper(`${API_URI}/items/deal-items-v3`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getInsertDealData() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(`${API_URI}/items/insert-deal`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOnGoingDealInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(`${API_URI}/items/ongoing-deal`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getBrandDealBanners(type) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${API_URI}/users/brand-deal-banners?type=${type}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async openTreasureBox(boxName) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ boxName }),
      }
      const res = await fetchWrapper(`${API_URI}/users/level/tbox`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkTreasureBoxTutorial() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${API_URI}/users/level/tbox/tutorial`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getShortMainBanners(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/users/image-short-banners',
      token,
      method,
      params,
    )
    return result
  }

  async getZeroWonStoreItemList(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/get-zero-won-store-item-list',
      token,
      method,
      params,
    )
    return result
  }

  async getZeroWonStoreCurrentSuccessReference(
    token,
    method = 'GET',
    params = {},
  ) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/get-zero-won-store-current-success-reference',
      token,
      method,
      params,
    )
    return result
  }

  async getAddressInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/users/addresses/default',
      token,
      method,
      params,
    )
    return result
  }

  async getAlFarmUserData(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/games/get-alfarm-userdata',
      token,
      method,
      params,
    )
    return result
  }

  async getEventPageMappingUrl(token, method = 'GET', params = {}) {
    const result = await fetcher('/event-page-mapping', token, method, params)
    return result
  }

  async createZeroWonStoreTeamInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/create-zero-won-store-team',
      token,
      method,
      params,
    )
    return result
  }

  async getZeroWonStoreFriendTeamInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/viralEngines/zero-won-store/get-zero-won-store-friend-team-info`,
      token,
      method,
      params,
    )
    return result
  }

  async updateZeroWonStoreTeamInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/update-zero-won-store-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async getZeroWonStoreTeamInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/get-zero-won-store-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayCheckInHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/check-today-checkin-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayCheckInReward(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/set-today-checkin-reward',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayAlfarmHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/check-today-alfarm-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayAlfarmReward(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/set-today-alfarm-reward',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayAlfarmLiveReviewHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/check-today-alfarm-live-review-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayAlfarmLiveReviewReward(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/set-today-alfarm-live-review-reward',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayAlfarmGganbuHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/check-today-alfarm-gganbu-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayAlfarmGganbuReward(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/set-today-alfarm-gganbu-reward',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayPurchaseHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/check-today-purchase-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayPurchaseReward(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/set-today-purchase-reward',
      token,
      method,
      params,
    )
    return result
  }

  async getUserBasicInfoWithAuthentication(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/users/get-users-basic-info',
      token,
      method,
      params,
    )
    return result
  }

  async getLureDealItems(token, method = 'GET', params = {}) {
    const result = await fetcher('/items/luredeal-items', token, method, params)
    return result
  }

  async getCurrentToggles(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/toggles', token, method, params)
    return result
  }

  async getZeroWonStoreUserInvitedInfos(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/get-user-invited-infos',
      token,
      method,
      params,
    )
    return result
  }

  async checkIsNewUser(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/viralEngines/zero-won-store/check-is-new-user',
      token,
      method,
      params,
    )
    return result
  }

  async fetchOngoingGroupPurchase(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/groupPurchase/ongoing',
      token,
      method,
      params,
    )
    return result
  }

  async fetchFinishedGroupPurchase(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/groupPurchase/finished',
      token,
      method,
      params,
    )
    return result
  }

  async fetchRewardAvailableGroupPurchase(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/groupPurchase/reward-avaiable',
      token,
      method,
      params,
    )
    return result
  }

  async fetchGroupPurchaseUserInfo(token, method = 'GET', params = {}) {
    const result = await fetcher('/groupPurchase/user', token, method, params)
    return result
  }

  async createGroupPurchaseUserInfo(token, method = 'POST', params = {}) {
    const result = await fetcher('/groupPurchase/user', token, method, params)
    return result
  }

  async groupPurchaseWithdraw(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/groupPurchase/withdraw',
      token,
      method,
      params,
    )
    return result
  }

  async groupPurchaseBankList(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/groupPurchase/bank-list',
      token,
      method,
      params,
    )
    return result
  }

  async groupPurchaseGiveReward({ reward, teamDealId, userInfo }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ reward, teamDealId, userInfo }),
      }
      const res = await fetchWrapper(
        `${API_URI}/groupPurchase/give-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async requestWithdrawReward({ userInfo }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userInfo }),
      }
      const res = await fetchWrapper(
        `${API_URI}/groupPurchase/request-withdraw`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordEventLogForWeb(method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/event-log-for-web',
      'token',
      method,
      params,
    )
    return result
  }

  async uploadUserImpressions(params = {}) {
    const token = AuthStore.token
    const result = await fetcher('/users/impressions', token, 'POST', params)
    return result
  }

  async getAbTestGroup(testNames = []) {
    const params = { testNames }
    const result = await fetcher(
      '/users/ab-test-group',
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async findTreasureTH(params = {}) {
    const result = await fetcher(
      '/treasureHunt/findTreasure',
      AuthStore.token,
      'PUT',
      params,
    )

    return result
  }

  async clickMatchTH(params = {}) {
    const result = await fetcher(
      '/treasureHunt/clickMatch',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async initTreasureHunt(token, params = {}) {
    const result = await fetcher('/treasureHunt/init', token, 'PUT', params)
    return result
  }

  async getMissionRewardTH(params = {}) {
    const result = await fetcher(
      '/treasureHunt/getMissionReward',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async fetchTreasureHuntItems({ skip = 0, categoryId = 0 }) {
    const result = await fetcher(
      `/treasureHunt/items?categoryId=${categoryId}&skip=${skip}`,
      AuthStore.token,
      'GET',
    )
    return result
  }

  async fetchTreasureHuntOrders({ skip = 0 }) {
    const result = await fetcher(
      `/treasureHunt/orders?skip=${skip}`,
      AuthStore.token,
      'GET',
    )
    return result
  }

  async treasureHuntGiveReward(params = {}) {
    const result = await fetcher(
      '/treasureHunt/giveReward',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async THSet(params = {}) {
    const result = await fetcher(
      `/treasureHunt/set`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async THInsert(params = {}) {
    const result = await fetcher(
      `/treasureHunt/insert`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async energyCooltime(params = {}) {
    const result = await fetcher(
      '/treasureHunt/energyCooltime',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async launchGPSUpdate(params = {}) {
    const result = await fetcher(
      '/treasureHunt/launchGPSData',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getDailyPointBonusData(params = {}) {
    const result = await fetcher(
      '/treasureHunt/getDailyPointBonusData',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getDailyPointBonus(params = {}) {
    const result = await fetcher(
      '/treasureHunt/getDailyPointBonus',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async collectPetGift(params = {}) {
    const result = await fetcher(
      '/treasureHunt/collectPetGift',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async sendPetTravel(params = {}) {
    const result = await fetcher(
      '/treasureHunt/sendPetTravel',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async rewardFriendMission(params = {}) {
    const result = await fetcher(
      '/treasureHunt/friend/reward',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async deleteFriend(params = {}) {
    const result = await fetcher(
      '/treasureHunt/friend/delete',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async friendMissionExecute(params = {}) {
    const result = await fetcher(
      '/treasureHunt/friend/mission/execute',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async openGift(params = {}) {
    const result = await fetcher(
      '/treasureHunt/friend/gift/open',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async updateFriendSearchMission(params = {}) {
    const result = await fetcher(
      '/treasureHunt/friend/update/find',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async fetchFriend(params = {}) {
    const result = await fetcher(
      '/treasureHunt/friend/fetch',
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async giveMissionReward(params = {}) {
    const result = await fetcher(
      '/treasureHunt/mission/giveReward',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async giveReward(params = {}) {
    const result = await fetcher(
      '/treasureHunt/giveReward',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async startUploadChallenge(params = {}) {
    const result = await fetcher(
      '/treasureHunt/challenge/upload/start',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async giveUploadChallengeReward(params = {}) {
    const result = await fetcher(
      '/treasureHunt/challenge/upload/reward',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async openStarbox(params = {}) {
    const result = await fetcher(
      '/treasureHunt/starbox/open',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async dateLog(params = {}) {
    const result = await fetcher(
      '/treasureHunt/date/log',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async purchaseScratchCard(params = {}) {
    const result = await fetcher(
      '/treasureHunt/scratch/purchase',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async achievementHandler(params = {}) {
    const result = await fetcher(
      '/treasureHunt/achievement',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async fetchDailyLogCount(params = {}) {
    const result = await fetcher(
      '/treasureHunt/achievement/dailyLog',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async THincrease(params = {}) {
    const result = await fetcher(
      `/treasureHunt/increase`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async closetDraw(params = {}) {
    const result = await fetcher(
      `/treasureHunt/closet/draw`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async giveGoldenEggReward(params = {}) {
    const result = await fetcher(
      `/treasureHunt/goldenEggReward`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async energyMachineReward(params = {}) {
    const result = await fetcher(
      `/treasureHunt/energyMachineReward`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getWatermelonGameData(params = {}) {
    const result = await fetcher(
      `/common/watermelon/fetch`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async watermelonStartGame(params = {}) {
    const result = await fetcher(
      `/common/watermelon/startGame`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  // 응모권 사용 경품 응모
  async watermelonEnterDraw(params = {}) {
    const result = await fetcher(
      `/common/watermelon/enterDraw`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async watermelonReachedFinal(params = {}) {
    const result = await fetcher(
      `/common/watermelon/reachedFinal`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  // 코인으로 아이템 구매
  async watermelonShopCoinProduct(params = {}) {
    const result = await fetcher(
      `/common/watermelon/ShopCoinProduct`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async watermelonReachedFinalInstant(params = {}) {
    console.warn(
      'Warning: watermelonReachedFinalInstant is deprecated and will be removed in future releases. Please use setWatermelonExp instead.',
    )
    const result = await fetcher(
      `/common/watermelon/reachedFinal/instant`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async commonSet(params = {}) {
    const result = await fetcher(`/common/set`, AuthStore.token, 'PUT', params)
    return result
  }

  async commonInsert(params = {}) {
    const result = await fetcher(
      `/common/insert`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getWatermelonContacts(params = {}) {
    const result = await fetcher(
      `/common/watermelon/rank/local/contacts`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async uploadWatermelonContacts(params = {}) {
    const result = await fetcher(
      `/common/watermelon/rank/local/contacts`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getWatermelonRankingAll(params = {}) {
    const result = await fetcher(
      `/common/watermelon/rank/all`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getWatermelonRankingLocal(params = {}) {
    const result = await fetcher(
      `/common/watermelon/rank/local`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async sendBoastAppPush(params = {}) {
    const result = await fetcher(
      `/common/watermelon/boast`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async setWatermelonDrawTicket(params = {}) {
    const result = await fetcher(
      `/common/watermelon/set/drawTicket`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async setWatermelonExp(params = {}) {
    const result = await fetcher(
      `/common/watermelon/set/exp`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async setWatermelonCoin(params = {}) {
    const result = await fetcher(
      `/common/watermelon/set/coin`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async setHighscore(params = {}) {
    const result = await fetcher(
      `/common/watermelon/set/highscore`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async setAllTimeHighscore(params = {}) {
    const result = await fetcher(
      `/common/watermelon/set/highscore/alltime`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async giveWatermelonMissionReward(params = {}) {
    const result = await fetcher(
      '/common/watermelon/mission/giveReward',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async checkQuotaLimit(params = {}) {
    const result = await fetcher(
      `/common/watermelon/checkQuotaLimit`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async emergencyShareReward(params = {}) {
    const result = await fetcher(
      `/common/watermelon/emergencyShareReward`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getUninstallUsersByContacts(params = {}) {
    const result = await fetcher(
      `/common/watermelon/uninstallUsersByContacts`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getChurnedUsersByContacts(params = {}) {
    const result = await fetcher(
      `/common/watermelon/churnedUsersByContacts`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getWinningItemReviews(type = 'onion') {
    try {
      const options = {
        method: 'GET',
      }
      const url = `${API_URI}/common/winning-item-review?type=${type}`
      const res = await fetchWrapper(url, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async startTeamGame(params = {}) {
    const result = await fetcher(
      `/common/watermelon/teamGame/start`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async endTeamGame(params = {}) {
    const result = await fetcher(
      `/common/watermelon/teamGame/end`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async exitTeamGame(params = {}) {
    const result = await fetcher(
      `/common/watermelon/teamGame/exit`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async fetchTeamgame(params = {}) {
    const result = await fetcher(
      `/common/watermelon/teamGame/fetch`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async checkTeamgameClear(params = {}) {
    const result = await fetcher(
      `/common/watermelon/teamGame/checkClear`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async bokUser(params = {}) {
    const result = await fetcher(
      `/common/bok/user`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async bokMessage(params = {}) {
    const result = await fetcher(
      `/common/bok/message`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async registerMessage(params = {}) {
    const result = await fetcher(
      `/common/bok/register-message`,
      AuthStore.publicToken,
      'PUT',
      params,
    )
    return result
  }

  async createOnboardingMessage(params = {}) {
    const result = await fetcher(
      `/common/bok/create-onboarding-message`,
      AuthStore.publicToken,
      'PUT',
      params,
    )
    return result
  }

  async setNickname(params = {}) {
    const result = await fetcher(
      `/common/bok/set-nickname`,
      AuthStore.publicToken,
      'PUT',
      params,
    )
    return result
  }

  async requestVerificationNumber(params = {}) {
    const result = await fetcher(
      '/users/verification-numbers',
      AuthStore.publicToken,
      'POST',
      params,
    )
    return result
  }

  async createDynamicLink(params = {}) {
    const result = await fetcher(
      `/common/bok/dynamic-link`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async bokUserData(params = {}) {
    const result = await fetcher(
      `/common/bok/user-data`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async logBokAction(params = {}) {
    const result = await fetcher(
      `/common/bok/log-action`,
      AuthStore.publicToken,
      'PUT',
      params,
    )
    return result
  }

  async registerMVNOPreLaunch(params = {}) {
    const result = await fetcher(
      `/common/mvno/prelaunch/register`,
      AuthStore.publicToken,
      'PUT',
      params,
    )
    return result
  }

  async getApplePangUser(params = {}) {
    const result = await fetcher(
      `/common/applePang/user`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async startApplePang(params = {}) {
    const result = await fetcher(
      `/common/applePang/start`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getApplepangRankingAll(params = {}) {
    const result = await fetcher(
      `/common/applePang/rank/all`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getApplePangRankingLocal(params = {}) {
    const result = await fetcher(
      `/common/applePang/rank/local`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async getSpecificItemsInfo(itemIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemIds,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/items/specific-items-info`,
        options,
      )
      const data = await res.json()
      return data?.data
    } catch (err) {
      console.log(err)
    }
  }

  async initiateEggMoney({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/initiate`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async claimEgg() {
    const result = await fetcher(
      `/eggMoney/claim-egg`,
      AuthStore.token,
      'PUT',
      {},
    )
    return result
  }

  async fetchEggMoneyUser() {
    const result = await fetcher(`/eggMoney/user`, AuthStore.token, 'GET', {})
    return result
  }

  async fetchMyEggs() {
    const result = await fetcher(
      `/eggMoney/fetch-my-eggs`,
      AuthStore.token,
      'GET',
      {},
    )
    return result
  }

  async fetchAllEggs() {
    const result = await fetcher(
      `/eggMoney/fetch-all-eggs`,
      AuthStore.token,
      'GET',
      {},
    )
    return result
  }

  async fetchEggAccountData({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/fetch-egg-account`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async fetchForSaleEggList() {
    const result = await fetcher(
      `/eggMoney/fetch-for-sale-egg-list`,
      AuthStore.token,
      'GET',
      {},
    )
    return result
  }

  async fetchEggTradeData({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/fetch-egg-trade-data`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async registerOrder({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/order/register`,
      AuthStore.token,
      'POST',
      params,
    )
    return result
  }

  async cancelOrder({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/order/cancel`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async eggMoneyNotificationSetting(
    eggMoneyNotificationSetting,
    eggMoneyNotificationTime,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eggMoneyNotificationSetting,
          eggMoneyNotificationTime,
        }),
      }
      const res = await fetchWrapper(
        `${API_URI}/users/egg-money-push-settings`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async eggMoneySet(params = {}) {
    const result = await fetcher(
      `/eggMoney/set`,
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async fetchTradeInfo({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/fetch-trade-info`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async fetchRecommendQueue({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/fetch-recommed-queue`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async fetchEggQueues({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/fetch-egg-queues`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async fetchEggOwners({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/fetch-egg-owners`,
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async bankList(token, method = 'GET', params = {}) {
    const result = await fetcher('/eggMoney/bank-list', token, method, params)
    return result
  }

  async withdraw(token, method = 'POST', params = {}) {
    const result = await fetcher('/eggMoney/withdraw', token, method, params)
    return result
  }

  async userLog({ params = {} }) {
    const result = await fetcher(
      `/eggMoney/log/behavior`,
      AuthStore.token,
      'POST',
      params,
    )
    return result
  }

  async kakaoShareCount({ params = {} }) {
    const result = await fetcher(
      '/eggMoney/kakao/shareCount',
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async fetchAllBookmarks({ params = {} }) {
    const result = await fetcher(
      '/eggMoney/bookmark/all',
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async bookmarkEgg({ params = {} }) {
    const result = await fetcher(
      '/eggMoney/bookmark/egg',
      AuthStore.token,
      'PUT',
      params,
    )
    return result
  }

  async rouletteInviteResult({ params = {} }) {
    const result = await fetcher(
      '/eggMoney/rouletteInvite/result',
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async getBannerAdData(params = {}) {
    const result = await fetcher(
      '/ad/traffic-ads',
      AuthStore.token,
      'GET',
      params,
    )
    return result
  }

  async logInternalAdEvent(params = {}) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      }
      fetchWrapper(`${API_URI}/ad/traffic-ads/log-banner-event`, options)
    } catch (err) {
      console.log(err)
    }
  }

  async logImpression(params = {}) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      }
      fetchWrapper(`${API_URI}/common/daily-check-in/log-impression`, options)
    } catch (e) {
      console.log(e)
    }
  }
}

export default new BackendApis()
