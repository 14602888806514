import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import AltoonAction from '../actions/AltoonAction'
import LoadingIndicator from '../components/atoms/loadingIndicator'
import MainNovelComponent from '../components/toon/MainNovelComponent'
import MainToonComponent from '../components/toon/mainToonComponent'
import THImageText from '../components/treasureHunt/THImageText'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis.ts'
import LeaderboardEventPage from './AltoonEventPage/LeaderboardEventPage'
import MissionEventPage from './AltoonEventPage/MissionEventPage'
import PollEventPage from './AltoonEventPage/PollEventPage'
import ThemeSaleEventPage from './AltoonEventPage/ThemeSaleEventPage'
import TimeSaleEventPage from './AltoonEventPage/TimeSaleEventPage'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const AltoonEventPage = observer(({ params }) => {
  const eventId = params.eventId
  const isFrom = params.isFrom

  const { pop } = useMyFlow()

  const [isLoading, setIsLoading] = useState(false)
  const [eventInfo, setEventInfo] = useState()
  const [toonsInfo, setToonsInfo] = useState()
  const [novelsInfo, setNovelsInfo] = useState()
  const [participated, setParticipated] = useState(false)
  const [leaderboardInfo, setLeaderboardInfo] = useState()

  useEffect(() => {
    const fetchData = async () => {
      if (eventId === 'none') {
        return
      }

      const eventInfo = await backendApis.loadToonEventInfo(eventId)
      if (eventInfo?.status === 200) {
        setEventInfo(eventInfo?.data)
        if (eventInfo?.data?.toonIdList?.length > 0) {
          const toonInfo = await backendApis.loadToonInfoByIds(
            eventInfo?.data?.toonIdList,
          )
          setToonsInfo(toonInfo?.data)
        }
        if (eventInfo?.data?.novelIdList?.length > 0) {
          const novelInfo = await backendApis.loadNovelInfoByIds(
            eventInfo?.data?.novelIdList,
          )
          setNovelsInfo(novelInfo?.data)
        }

        if (eventInfo?.data?.eventType === 'leaderboard') {
          const leaderboardInfo = await backendApis.loadLeaderboardInfo(eventId)
          setLeaderboardInfo(leaderboardInfo?.data?.slice(0, 10))

          if (
            leaderboardInfo?.data?.find(
              (e) =>
                e?.userId.toString() === UserStore?.userInfo?._id.toString(),
            )
          ) {
            setParticipated(true)
          }
        }
      }
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    setIsLoading(true)
    fetchData()
    backendApis.logToonAction('AltoonEventPage', 'enteredScreen', '')
    setIsLoading(false)
  }, [participated])

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 w-full bg-white z-20 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='text-[4.2vw] py-2 whitespace-nowrap'>
          {eventInfo?.eventTitle?.length > 20
            ? `${eventInfo?.eventTitle?.slice(0, 20)}..`
            : eventInfo?.eventTitle}
        </div>
        <button
          type='button'
          className='px-[4vw] text-[3.6vw]'
          onClick={() => {
            backendApis.logToonAction('AltoonEventPage', 'linkShare', '')

            AltoonAction.linkShare({
              shareType: 'Altoon_eventShare',
              title: `[올툰 이벤트]`,
              description: `[${eventInfo?.eventTitle}] 이벤트가 진행중이에요`,
              imageUrl: eventInfo?.bannerImageUri ?? '',
              payload: {
                eventId,
              },
            })
          }}
        >
          <img
            src='/images/toon/shareIcon.png'
            alt='share'
            className='w-[5vw] h-[5vw]'
          />
        </button>
      </section>
    )
  }

  const MainImageSection = () => {
    if (!eventInfo?.eventImageUri) {
      return
    }
    return (
      <div className={isFrom !== 'AltoonMainEventPage' ? 'pt-[16vw]' : ''}>
        <img
          src={eventInfo?.eventImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const SubImageSection = () => {
    return (
      <div className='pt-[16vw]'>
        <img
          src={eventInfo?.subImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const DramaPosterSection = ({
    dramaId = '66445534573d90c7474a0bb3',
    posterImage = 'https://assets.ilevit.com/404e45eb-8a6c-47e1-bd0f-87081285747d.png',
  }) => {
    return (
      <button
        type='button'
        aria-label='dramaPoster'
        className='w-full h-full pb-[10vw]'
        onClick={() => {
          backendApis.logToonAction('AltoonMainBanner', 'clickedDramaPoster', {
            dramaId,
          })
          const webUrl = 'https://alwayz-drama-front.ilevit.com'
          const redirectSearchParams = new URLSearchParams({
            token: AuthStore.token,
            randomString: Math.random().toString(36).slice(2, 10),
          })
          const prop = {
            screen: 'Drama',
            prop: {
              eventPageMapping: {
                redirectUrl: `${webUrl}/?${redirectSearchParams}`,
              },
              isFrom: 'Altoon',
              dramaId,
            },
          }
          window.location.href = `#navigate.${JSON.stringify(prop)}`
        }}
      >
        <img src={posterImage} alt='' className='w-full h-full object-cover' />
      </button>
    )
  }

  const CTAButtonOne = () => {
    return (
      <div className='w-full py-[4vw] flexRow justify-center items-center text-[4vw]'>
        <button
          type='button'
          style={{
            borderColor: `#${eventInfo?.ctaOneInfo?.ctaBorderColor}`,
            color: `#${eventInfo?.ctaOneInfo?.ctaTextColor}`,
            backgroundColor: `#${eventInfo?.ctaOneInfo?.ctaColor}`,
          }}
          className={`py-4 w-[70vw] rounded-lg font-bold text-[5vw] text-center ${
            eventInfo?.ctaOneInfo?.ctaBorderColor ? 'border-2' : ''
          }`}
          onClick={async () => {
            if (eventInfo?.setStorageInfo) {
              window.localStorage.setItem(
                'toon_default_tab',
                eventInfo?.setStorageInfo,
              )
            }
            if (eventInfo?.ctaOneInfo?.ctaType === 'setAlarm') {
              const alarmSet = await backendApis.setToonAlarm(
                `setAlarm_${eventInfo?.description}`,
                eventInfo?._id,
              )
              if (alarmSet?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🔔',
                  message: '알림이 설정되었어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
                pop()
              }
            } else {
              // navigate(
              //   `/${eventInfo?.ctaOneInfo?.ctaPath}?token=${AuthStore?.token}&${eventInfo?.ctaOneInfo?.ctaProp}`,
              // )
              pop()
            }
          }}
        >
          {eventInfo?.ctaOneInfo?.ctaText || '바로가기'}
        </button>
      </div>
    )
  }

  const CTAButtonTwo = () => {
    return (
      <div className='w-full pt-[4vw] pb-[10vw] flexRow justify-center items-center text-[4vw]'>
        <button
          type='button'
          style={{
            borderColor: `#${eventInfo?.ctaTwoInfo?.ctaBorderColor}`,
            color: `#${eventInfo?.ctaTwoInfo?.ctaTextColor}`,
            backgroundColor: `#${eventInfo?.ctaTwoInfo?.ctaColor}`,
          }}
          className={`py-4 w-[70vw] rounded-lg font-bold text-[5vw] text-center ${
            eventInfo?.ctaTwoInfo?.ctaBorderColor ? 'border-2' : ''
          }`}
          onClick={async () => {
            if (eventInfo?.setStorageInfo) {
              window.localStorage.setItem(
                'toon_default_tab',
                eventInfo?.setStorageInfo,
              )
            }
            if (eventInfo?.ctaTwoInfo?.ctaType === 'setAlarm') {
              const alarmSet = await backendApis.setToonAlarm(
                `setAlarm_${eventInfo?.description}`,
                eventInfo?._id,
              )
              if (alarmSet?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🔔',
                  message: '알림이 설정되었어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            } else {
              // navigate(
              //   `/${eventInfo?.ctaTwoInfo?.ctaPath}?token=${AuthStore?.token}&${eventInfo?.ctaTwoInfo?.ctaProp}`,
              // )
              pop()
            }
          }}
        >
          {eventInfo?.ctaTwoInfo?.ctaText || '바로가기'}
        </button>
      </div>
    )
  }

  const ToonsAndNovelsList = () => {
    return (
      <div className='pb-4'>
        <div className='px-4 pt-6 pb-4 font-bold text-[4.8vw] text-white'>
          {eventInfo?.toonListTitle || '이벤트 대상 작품목록'}
        </div>
        {novelsInfo?.length > 0 &&
          novelsInfo?.map((novel, index) => (
            <div
              key={novel?._id}
              className='mx-4 mb-4 rounded-lg border bg-white'
            >
              <MainNovelComponent
                novel={novel}
                index={index}
                type='eventPage'
              />
            </div>
          ))}
        {toonsInfo?.length > 0 &&
          toonsInfo?.map((toon, index) => (
            <div
              key={toon?._id}
              className='mx-4 mb-4 rounded-lg border bg-white'
            >
              <MainToonComponent
                toon={toon}
                index={index}
                type='eventPage'
                component='eventPage_toon'
              />
            </div>
          ))}
      </div>
    )
  }

  const NoticeSection = () => {
    return (
      <div className='w-full px-6 pt-4 pb-[10vw] bg-gray-200'>
        <div className='text-[3.6vw] font-semibold'>유의사항</div>
        <THImageText
          fontSize={2.8}
          style={{
            color: '#8E8D9B',
          }}
          text={`${eventInfo?.notice}`}
        />
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        style={{
          backgroundColor: `#${eventInfo?.backgroundColor}`,
        }}
      >
        {eventInfo && (
          <>
            {isFrom !== 'AltoonMainEventPage' && <HeaderComponent />}

            <MainImageSection />

            {(!eventInfo?.eventType || eventInfo.eventType === 'basic') && (
              <>
                {eventInfo?.ctaOneInfo?.ctaText && <CTAButtonOne />}
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
                {eventInfo?.ctaTwoInfo?.ctaText && <CTAButtonTwo />}
              </>
            )}

            {eventInfo.eventType === 'mission' && (
              <MissionEventPage eventInfo={eventInfo} />
            )}

            {eventInfo.eventType === 'poll' && (
              <>
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) &&
                  toonsInfo && (
                    <PollEventPage
                      eventInfo={eventInfo}
                      toonsInfo={toonsInfo}
                    />
                  )}
              </>
            )}

            {eventInfo.eventType === 'leaderboard' && (
              <>
                <LeaderboardEventPage
                  participated={participated}
                  setParticipated={setParticipated}
                  eventInfo={eventInfo}
                  leaderboardInfo={leaderboardInfo}
                />
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
              </>
            )}

            {eventInfo.eventType === 'timeSale' && (
              <>
                {eventInfo?.toonIdList?.length > 0 && (
                  <TimeSaleEventPage
                    eventInfo={eventInfo}
                    toonsInfo={toonsInfo}
                  />
                )}
              </>
            )}

            {eventInfo.eventType === 'themeSale' && (
              <>
                {eventInfo?.toonIdList?.length > 0 && (
                  <ThemeSaleEventPage
                    eventInfo={eventInfo}
                    toonsInfo={toonsInfo}
                  />
                )}
              </>
            )}

            {eventInfo?.eventType === 'drama' && (
              <>
                <DramaPosterSection
                  dramaId={eventInfo?.dramaId}
                  posterImage={eventInfo?.dramaPosterImage}
                />
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
              </>
            )}

            {eventInfo?.subImageUri && <SubImageSection />}
            {eventInfo?.notice && <NoticeSection />}
          </>
        )}
        {isLoading && <LoadingIndicator isLoading={isLoading} />}
      </div>
    </AppScreen>
  )
})

export default AltoonEventPage
